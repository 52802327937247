import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import Big from 'big.js';
import styled from 'styled-components';

import {
  getOrderRowTotalByTopicId,
  getOrderRowReceivedTotalByTopicId,
} from '../../../../store/reducers/orderRow';
import { getTargetTopics } from '../../../../store/reducers/target/targetRows';
import { getIsOuterBarOpen } from '../../../../store/reducers/ui';
import { getWorkPackageById } from '../../../../store/reducers/workPackage';

import { requestTargetRows } from '../../../../store/actions/target/targetRow';
import { updateTopic } from '../../../../store/actions/topic';

import {
  APITopic,
  APITopicPutBody,
  APIWorkPackage,
} from '../../../../types/api';
import { ViewModeOptions } from '../../../../types/general';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import Cell from '../../../../components/Cell';
import {
  OrderRowRightPaddingContainer,
  OrderRowSmallerRightPaddingContainer,
} from '../../../../components/Containers';
import Checkbox from '../../../../components/Input/Checkbox';
import { BoldedPrimaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';
import { useDebounce } from '../../../../utils/hooks';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { IconDown, IconRight, IconToLinkedEntity } from '../../../../assets';

import { generateUrl, routes } from '../../../../routes';
import EditableTopicNameCell from '../../ViewModeWrappers/Edit/components/EditableTopicNameCell';
import { TargetCell } from '../Target/TargetedRow';
import TopicRowEditControls from './TopicRowEditControls';
import TopicRowReceiveControls from './TopicRowReceiveControls';
import WorkPackageSelectionCell from './WorkPackageSelectionCell';

type TopicRowProps = {
  projectId: string;
  topic: APITopic;
  viewModeOptions: ViewModeOptions;
  isOpen: boolean;
  onToggle: () => void;
  onChoose: (topicId: string, orderRowIds: string[]) => void;
  isSelected: boolean;
  focus?: boolean;
};

const TopicRow = React.forwardRef(
  (
    {
      projectId,
      topic,
      viewModeOptions,
      isOpen,
      onToggle,
      onChoose,
      isSelected,
      focus,
    }: TopicRowProps,
    ref: React.ForwardedRef<HTMLTableRowElement>
  ) => {
    const total = useSelector(getOrderRowTotalByTopicId(topic.id));
    const outerBarOpen = useSelector(getIsOuterBarOpen());

    const receivedTotal = useSelector(
      getOrderRowReceivedTotalByTopicId(topic.id)
    );

    const targetTopics =
      useRemoteData(
        getTargetTopics(topic.orderId),
        requestTargetRows({ orderId: topic.orderId })
      ) ?? [];

    const onKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
      if (isClickOrKeyboardSelection(e)) {
        e.preventDefault();
        e.stopPropagation();
        onToggle();
      }
    };

    const onClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();
      onToggle();
    };

    const { workPackageId } = topic;

    const workPackage = useSelector(getWorkPackageById(workPackageId ?? ''));

    const targetTopic = targetTopics.find((row) => row.id === topic.id);

    const targetAmount = targetTopic ? targetTopic.target : new Big(0);

    const targetDifference = targetTopic?.difference;

    const colSpanLength = () => {
      if (
        viewModeOptions.type !== 'receive' &&
        !(viewModeOptions.showTargetRows && outerBarOpen)
      ) {
        return 2;
      }

      return 0;
    };

    return (
      <BoldedPrimaryRow
        clickable
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={focus ? 0 : -1}
        data-testid={`topic-row-${topic.id}`}
        ref={ref}
      >
        {viewModeOptions.type === 'edit' ? (
          <Cell>
            <Checkbox
              checked={isSelected}
              onChange={() => {
                onChoose(topic.id, topic.orderRowIds);
              }}
            />
          </Cell>
        ) : null}
        <Cell align="center">
          <img
            src={isOpen ? IconDown : IconRight}
            alt={isOpen ? 'Aihe on auki' : 'Aihe  on suljettu'}
          />
        </Cell>
        <TopicName
          projectId={projectId}
          viewMode={viewModeOptions.type}
          topic={topic}
          workPackage={workPackage}
          outerBarOpen={outerBarOpen}
        />
        {viewModeOptions.showTargetRows ? (
          <TargetCell align="right">
            {big.priceFormat(targetAmount, outerBarOpen ? 0 : undefined)}
          </TargetCell>
        ) : null}
        <Cell
          align="right"
          contentContainer={
            viewModeOptions.showTargetRows || outerBarOpen
              ? OrderRowSmallerRightPaddingContainer
              : OrderRowRightPaddingContainer
          }
        >
          {big.priceFormat(total, outerBarOpen ? 0 : undefined)}
        </Cell>
        {viewModeOptions.showTargetRows ? (
          <TargetCell align="right">
            {targetDifference
              ? big.priceFormat(targetDifference, outerBarOpen ? 0 : undefined)
              : ''}
          </TargetCell>
        ) : null}
        <Cell />
        <Cell align="right">
          {big.priceFormat(receivedTotal, outerBarOpen ? 0 : undefined)}
        </Cell>
        {viewModeOptions.type === 'receive' ||
        (viewModeOptions.showTargetRows && outerBarOpen) ? null : (
          <Cell colSpan={colSpanLength()} />
        )}
        {viewModeOptions.type === 'receive' ? (
          <TopicRowReceiveControls
            viewModeOptions={viewModeOptions}
            topicId={topic.id}
          />
        ) : null}
        <Cell align="right">
          {big.priceFormat(
            total.minus(receivedTotal),
            outerBarOpen ? 0 : undefined
          )}
        </Cell>
        {viewModeOptions.type === 'edit' ? (
          <TopicRowEditControls topic={topic} />
        ) : null}
      </BoldedPrimaryRow>
    );
  }
);

const StyledImg = styled.img`
  padding-left: ${({ theme }) => theme.margin[8]};
  vertical-align: middle;
`;

// TODO FIXME: Quick Hax. It would be better to have different topic rows for
// each mode and not this kind of components. It would be even better to have
// different react trees for each mode. Gotta live with incremental change.
type TopicNameProps = {
  projectId: string;
  viewMode: 'edit' | 'normal' | 'receive';
  topic: APITopic;
  workPackage?: APIWorkPackage;
  outerBarOpen: boolean;
};

export const MAX_TOPIC_LENGTH = 200;

const isValidName = (name: string | null) => {
  if (!name) {
    return false;
  }

  return name.length < MAX_TOPIC_LENGTH;
};

const TopicName = ({
  projectId,
  viewMode,
  topic,
  workPackage,
  outerBarOpen,
}: TopicNameProps) => {
  const [update, setUpdate] = React.useState<{
    name: string;
    workPackageId: string | null;
  }>({
    name: topic.name,
    workPackageId: topic.workPackageId,
  });

  const [isValid, setValid] = React.useState<{
    name: boolean;
    workPackageId: boolean;
  }>({
    name: true,
    workPackageId: true,
  });

  const iconAltText = useTxt('order.topicRow.icon.alt');

  const dispatch = useDispatch();

  const debouncedUpdate = useDebounce((data: APITopicPutBody) => {
    dispatch(updateTopic(topic.id, data));
  }, 1000);

  const setTopic = <K extends keyof APITopicPutBody>(key: K) => (
    value: string | null
  ) => {
    setUpdate({ ...update, [key]: value });

    switch (key) {
      case 'name': {
        return setValid({ ...isValid, name: isValidName(value) });
      }
    }
  };

  const allValid = Object.values(isValid).every((value) => value === true);

  useUpdateEffect(() => {
    if (update && allValid) {
      debouncedUpdate({
        updatedAt: topic.updatedAt,
        ...update,
      });
    }
  }, [update, allValid]);

  if (viewMode === 'edit') {
    return (
      <>
        <EditableTopicNameCell
          colSpan={1}
          topicId={topic.id}
          setTopic={setTopic}
          name={update.name}
          isValid={isValid.name}
          onBlur={() => debouncedUpdate.flush()}
        />
        <WorkPackageSelectionCell
          colSpan={outerBarOpen ? 2 : 3}
          projectId={projectId}
          topicId={topic.id}
          setTopic={setTopic}
        />
      </>
    );
  }

  return (
    <Cell colSpan={outerBarOpen ? 3 : 4} className="topicName">
      {topic.name}
      {workPackage ? (
        <>
          <StyledCodeName>
            {workPackage.code} | {workPackage.name}
          </StyledCodeName>
          <Link
            to={generateUrl({
              route: routes.WORKSECTION_EXPANDED_WITH_TOPIC_OPEN,
              projectId,
              workPackageId: workPackage?.id,
              topicId: topic.id,
            })}
          >
            <StyledImg src={IconToLinkedEntity} alt={iconAltText} />
          </Link>
        </>
      ) : null}
    </Cell>
  );
};

const StyledCodeName = styled.i`
  padding-left: ${(props) => props.theme.margin[8]};
`;

export default TopicRow;
