import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { getTopicsByOrderId } from '../../../store/reducers/topic';
import { getActiveProjectId } from '../../../store/reducers/ui';

import useTxt from '../../../hooks/useTxt';

import Cell from '../../../components/Cell';
import { PrimaryRow } from '../../../components/Table';

import { isDefined } from '../../../utils/general';
import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconToLinkedEntity } from '../../../assets';
import { IconDown, IconRight } from '../../../assets/svg';

import { ToggleButton, Toggle } from '../../../context/toggleContext';
import { generateUrl, routes } from '../../../routes';
import { OrderRowTable } from './OrderRowTable';
import { ProgressBar } from './ProgressBar';

type TopicRowProps = {
  id: string;
  name: string;
  orderId: string;
  allSectionsOpen: boolean;
};

const TopicRow = ({ id, name, orderId, allSectionsOpen }: TopicRowProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';
  const [isOpen, setIsOpen] = useState(allSectionsOpen);
  const history = useHistory();
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { hash } = history.location;

  useEffect(() => {
    // TODO: find a way to define this in routes.ts
    if (hash === `#topic-${id}`) {
      rowRef.current?.scrollIntoView();
      setIsOpen(true);
    }
  }, [hash, id]);

  useEffect(() => {
    setIsOpen(allSectionsOpen);
  }, [allSectionsOpen]);

  const iconAltText = useTxt(
    'worksectionDetails.orderTable.linkToEntityIcon.alt'
  );
  const openAltText = useTxt('worksectionDetails.orderTable.topicRow.open');
  const closedAltText = useTxt('worksectionDetails.orderTable.topicRow.closed');

  const toggleIsOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsOpen((open) => !open);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      toggleIsOpen(e);
    }
  };

  return (
    <>
      <PrimaryRow
        clickable
        onClick={toggleIsOpen}
        onKeyPress={onKeyPress}
        tabIndex={0}
        id={`topic-${id}`}
        ref={rowRef}
      >
        <Cell />
        <Cell>
          <Toggle>
            <ToggleButton
              icon={isOpen ? IconDown : IconRight}
              label={isOpen ? openAltText : closedAltText}
              onClick={toggleIsOpen}
            />
          </Toggle>
        </Cell>
        <Cell colSpan={8}>
          <StyledName>{name}</StyledName>
          <Link
            to={generateUrl({
              route: routes.ORDER_WITH_OPEN_TOPIC,
              projectId,
              orderId,
              topicId: id,
            })}
          >
            <StyledImg src={IconToLinkedEntity} alt={iconAltText} />
          </Link>
        </Cell>
        <RightPaddedCell>
          <ProgressBar topicId={id} />
        </RightPaddedCell>
        <Cell />
      </PrimaryRow>
      {isOpen ? <OrderRowTable topicId={id} /> : undefined}
    </>
  );
};

const StyledName = styled.span`
  padding-right: ${({ theme }) => theme.margin[8]};
`;

const StyledImg = styled.img`
  vertical-align: middle;
`;

const RightPaddedCell = styled(Cell)`
  padding-right: ${({ theme }) => theme.margin[24]};
`;

type TopicProps = {
  orderId: string;
  allSectionsOpen: boolean;
};

export const Topic = ({ orderId, allSectionsOpen }: TopicProps) => {
  const { workPackageId } = useParams<{ workPackageId: string }>();

  const topicsForOrder = Object.values(useSelector(getTopicsByOrderId(orderId)))
    .filter(isDefined)
    .filter((topic) => topic.workPackageId === workPackageId);

  return (
    <>
      {topicsForOrder.map(({ id, name }) => (
        <TopicRow
          key={id}
          id={id}
          name={name}
          orderId={orderId}
          allSectionsOpen={allSectionsOpen}
        />
      ))}
    </>
  );
};
