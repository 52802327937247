import Big from 'big.js';
import { Reducer } from 'redux';

import { ID } from '../../types/general';

import { BackendError } from '../../utils/api';
import { flow } from '../../utils/function';
import { isDefined } from '../../utils/general';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import {
  assertActionPayloadIsNotApiUpdatedEntities,
  isUpdatedEntitiesActionType,
} from './utils';

import { ActionTypes } from '../actionTypes';

import { AppState } from '.';

export type APIProject = {
  id: ID;
  companyId: ID;
  name: string;
  code: string;
  status: string;
  isClosed: boolean;
  externalUrl?: string | null;
  procurementAreaIds: ID[];
  workPackageIds: ID[];
  simplified: boolean;

  receivedTotal?: Big;
  targetTotal?: Big;
  additionalTargetTotal?: Big;
  costPredictionTotal?: Big;
  contractTotal?: Big;
  changeOrdersTotal?: Big;
  reservesTotal?: Big;
  revenueTotal?: Big;

  latestSnapshotTargetTotal?: Big;
  latestSnapshotCostsTotal?: Big;
  latestSnapshotContractTotal?: Big;
  latestSnapshotChangeOrdersTotal?: Big;
  latestSnapshotReservesTotal?: Big;
  latestSnapshotRevenueTotal?: Big;

  targetChangeFromLatest?: Big;
  costPredictionChangeFromLatest?: Big;
  contractChangeFromLatest?: Big;
  changeOrdersChangeFromLatest?: Big;
  reservesChangeFromLatest?: Big;
  revenuePredictionChangeFromLatest?: Big;

  nextgenProjectId: string | null;
  nextgenDefaultScheduleViewId: string | null;
};

export type ProjectState = {
  allProjectsRequest: remoteData.RemoteData<
    undefined,
    BackendError | undefined
  >;
  projectRequests: Partial<
    Record<string, remoteData.RemoteData<undefined, BackendError | undefined>>
  >;
  data: Partial<Record<string, APIProject>>;
};

const initialState: ProjectState = {
  allProjectsRequest: remoteData.notAsked,
  projectRequests: {},
  data: {},
};

const projectReducer: Reducer<ProjectState, ActionTypes> = (
  state = initialState,
  action
): ProjectState => {
  switch (action.type) {
    case 'GET_PROJECTS_STARTED': {
      return { ...state, allProjectsRequest: remoteData.loading };
    }
    case 'GET_PROJECTS_FAILURE': {
      return { ...state, allProjectsRequest: remoteData.fail(undefined) };
    }
    case 'GET_PROJECTS_SUCCESS': {
      const projects = action.payload;

      const previousDetailedProjects = Object.values(state.data)
        .filter(isDefined)
        .filter((project) => !project.simplified);

      const filteredProjects = projects.filter(
        (project) =>
          !previousDetailedProjects.some(({ id }) => id === project.id)
      );

      const normalizedProjects = normalizeBy('id', [
        ...previousDetailedProjects,
        ...filteredProjects,
      ]);

      return {
        ...state,
        allProjectsRequest: remoteData.succeed(undefined),
        data: normalizedProjects,
      };
    }
    case 'GET_PROJECT_STARTED': {
      const { projectId } = action.payload;

      return {
        ...state,
        projectRequests: {
          ...state.projectRequests,
          [projectId]: remoteData.loading,
        },
      };
    }
    case 'GET_PROJECT_FAILURE': {
      const { projectId, error } = action.payload;

      return {
        ...state,
        projectRequests: {
          ...state.projectRequests,
          [projectId]: remoteData.fail(error),
        },
      };
    }
    case 'GET_PROJECT_SUCCESS': {
      const { projectId, projects } = action.payload;

      return {
        ...state,
        projectRequests: {
          ...state.projectRequests,
          [projectId]: remoteData.succeed(undefined),
        },
        data: { ...state.data, [projectId]: projects[0] },
      };
    }
  }

  if (isUpdatedEntitiesActionType(action)) {
    const { projects: updatedProjects = [] } = action.payload;

    if (
      state.allProjectsRequest.kind !== 'Success' ||
      updatedProjects.length < 1
    ) {
      return state;
    }

    const previousValues = state.data;

    const updatedState = updatedProjects.reduce((nextState, project) => {
      const { id } = project;

      return {
        ...nextState,
        [id]: project,
      };
    }, previousValues);

    return { ...state, data: updatedState };
  }

  assertActionPayloadIsNotApiUpdatedEntities(action);

  return state;
};

export default projectReducer;

export const getProjectById = (projectId: string) => ({
  projects,
}: AppState): APIProject | undefined => {
  if (projects.allProjectsRequest.kind !== 'Success') {
    return undefined;
  }

  const project = projects.data[projectId];

  return project;
};

export const selectAllProjectsRequest = () => ({
  projects: { allProjectsRequest },
}: AppState) => allProjectsRequest;

export const selectProjects = () => ({ projects: { data } }: AppState) =>
  remoteData.succeed(data);

export const getProject: (
  projectId: string
) => (appState: AppState) => remoteData.RemoteData<APIProject | undefined> = (
  projectId
) =>
  flow(selectProjects(), (remoteProjects) =>
    remoteData.map(remoteProjects, (projects) => projects[projectId])
  );

export const getProjects = (state: AppState) => {
  if (state.projects.allProjectsRequest.kind === 'Success') {
    return remoteData.succeed(state.projects.data);
  }

  return state.projects.allProjectsRequest;
};

export const selectProjectRequest = (id: string) => ({
  projects: {
    projectRequests: { [id]: remoteProjects = remoteData.notAsked },
  },
}: AppState) => remoteProjects;
