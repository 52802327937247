import React from 'react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import { APITask, APIProjectUsers } from '@customtypes/api';

import { selectProjectUsers } from '../../../../../../store/reducers/projectUsers';
import { getTasks } from '../../../../../../store/reducers/tasks';
import { getActiveProjectId } from '../../../../../../store/reducers/ui';

import { fetchProjectUsers } from '../../../../../../store/actions';

import useRemoteData from '../../../../../../hooks/useRemoteData';
import useTxt from '../../../../../../hooks/useTxt';

import Cell from '../../../../../../components/Cell';

import { IconDelegate } from '../../../../../../assets/svg';

import AssigneeView from './AssigneeView';
import AssignerView from './AssignerView';

type InvoicesDelegationRowProps = {
  invoiceHeaderId: string;
  currentUserId: string;
  outerBarOpen: boolean;
  onShowOuterBar: (e: React.SyntheticEvent) => void;
};

const InvoicesDelegationRow = ({
  invoiceHeaderId,
  currentUserId,
  onShowOuterBar,
  outerBarOpen,
}: InvoicesDelegationRowProps) => {
  const projectId = useSelector(getActiveProjectId) ?? '';

  const tasks = useSelector(getTasks).filter(
    (task) => task.purchaseInvoiceHeaderId === invoiceHeaderId
  );

  const projectUsers =
    useRemoteData(
      selectProjectUsers(projectId ?? ''),
      fetchProjectUsers(projectId ?? '')
    ) ?? [];

  const assignedTask = tasks.find(
    (task) => task.assigneeUserId === currentUserId
  );

  const assignerUser = projectUsers.find(
    (user) => user.id === assignedTask?.assignerUserId
  );

  // As an assinor, you only see tasks that you assigned
  const taskAssignerUserIds = tasks.map((t) => t.assignerUserId);
  const taskAssigneeUserIds = tasks.map((t) => t.assigneeUserId);
  const isAssignor = taskAssignerUserIds.includes(currentUserId);
  const isAssignee = taskAssigneeUserIds.includes(currentUserId);

  const reviewRequestFromMe = useTxt(
    'order.receiveMode.invoice.delegate.reviewRequestFromMe'
  );

  const reviewRequestToMe = useTxt(
    'order.receiveMode.invoice.delegate.reviewRequestToMe',
    {
      assignerName: `${assignerUser?.name}` ?? '',
    }
  );

  // invoice delegation row should only be visible to assignee or assonor users

  if (!isAssignee && !isAssignor) {
    return null;
  }

  const getTaskAssigneeUser = (task: APITask): APIProjectUsers =>
    projectUsers.filter((user) => user.id === task.assigneeUserId)[0];

  return (
    <tr>
      <StyledCell colSpan={outerBarOpen ? 11 : 17}>
        <IconDelegate />
        {isAssignor ? (
          <Task type="assigner">
            <TaskType>{reviewRequestFromMe}</TaskType>
            {tasks
              .filter((t) => t.assignerUserId === currentUserId)
              .map((task) => {
                const assignee = getTaskAssigneeUser(task);

                return (
                  <AssignerView key={task.id} assignee={assignee} task={task} />
                );
              })}
          </Task>
        ) : null}
        {isAssignee && assignedTask ? (
          <Task type="assignee" hasAssigned={isAssignor}>
            <TaskType>{reviewRequestToMe}</TaskType>
            <AssigneeView
              assignedTask={assignedTask}
              onShowOuterBar={onShowOuterBar}
            />
          </Task>
        ) : null}
      </StyledCell>
    </tr>
  );
};

const StyledCell = styled(Cell)`
  border-left: 1px solid ${(props) => props.theme.color.toolbarHighlight};
  border-right: 1px solid ${(props) => props.theme.color.toolbarHighlight};

  padding-left: 1rem;

  height: 2rem;

  background-color: ${(props) => props.theme.color.lightBlue};
`;

interface TaskProps {
  type: 'assigner' | 'assignee';
  hasAssigned?: boolean;
}

const Task = styled.div<TaskProps>`
  padding-left: 0.75rem;

  height: 2rem;

  display: flex;
  align-items: center;

  gap: 1rem;

  ${({ type, hasAssigned }) =>
    type === 'assignee' &&
    css`
      padding-left: ${hasAssigned ? '1.75rem' : 'none'};
      width: ${hasAssigned ? '100%' : 'auto'};
    `}
`;

export const TaskType = styled.span`
  font-weight: 600;
`;

export default InvoicesDelegationRow;
