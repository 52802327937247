import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Big from 'big.js';
import styled from 'styled-components';

import { getOrderById } from '../../../../store/reducers/order/order';
import { getTargetRowById } from '../../../../store/reducers/target/targetRows';
import { getTopic } from '../../../../store/reducers/topic';
import {
  getTargetViewTargetRowFilterState,
  getTargetViewFilterSearchWord,
} from '../../../../store/reducers/ui';
import { getWorkPackageById } from '../../../../store/reducers/workPackage';

import Cell from '../../../../components/Cell';
import { SecondaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { generateUrl, routes, useParams } from '../../../../routes';
import { fontSizeCss } from '../../../../styles/additionals';

type TargetRowProps = {
  rowId: string;
};

const TargetRow = ({ rowId }: TargetRowProps) => {
  const { projectId } = useParams(routes.TARGET);

  const targetRow = useSelector(getTargetRowById(rowId));
  const order = useSelector(getOrderById(targetRow?.orderId ?? ''));
  const topic = useSelector(getTopic(targetRow?.topicId ?? ''));

  const filterSearchWord = useSelector(getTargetViewFilterSearchWord());

  const isFiltered = useSelector(getTargetViewTargetRowFilterState(rowId));

  const isAnyFilter = filterSearchWord.length > 0;

  const history = useHistory();

  const workPackage = useSelector(
    getWorkPackageById(topic?.workPackageId ?? '')
  );

  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent,
    callback: () => void
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      callback();
    }
  };

  const onClickOrder = (orderId: string, topicId: string) => {
    history.push(
      generateUrl({
        route: routes.ORDER_WITH_OPEN_TOPIC,
        projectId,
        orderId,
        topicId,
        showTargetRows: 'showTarget',
      })
    );
  };

  const onClickWorkPackage = (workPackageId: string) => {
    const nextUrl = generateUrl({
      route: routes.WORKSECTION_EXPANDED,
      projectId,
      workPackageId,
    });

    history.push(nextUrl);
  };

  if (!targetRow) {
    return null;
  }

  if (isAnyFilter && !isFiltered) {
    return null;
  }

  const orderCodeName = order ? `${order.visibleCode} ${order.name}` : '';

  const workPackageCodeName = workPackage
    ? `${workPackage.code} ${workPackage.name}`
    : '';

  return (
    <>
      <StyledSecondaryRow key={rowId}>
        <StyledCell>
          <StyledReferencNoCellDiv>
            {targetRow.referenceNumber}
          </StyledReferencNoCellDiv>
        </StyledCell>
        <StyledCell colSpan={2}>{targetRow.description}</StyledCell>
        <StyledCell align="right">
          {`${big.amountFormat(targetRow.quantity ?? new Big(0))}${
            targetRow.unit ? ` ${targetRow.unit}` : ''
          }`}
        </StyledCell>
        <StyledCell align="right">
          {`${big.priceFormat(targetRow.unitPrice ?? new Big(0))}${
            targetRow.unit ? `/${targetRow.unit}` : ''
          }`}
        </StyledCell>
        <StyledCell align="right">
          {big.priceFormat(
            targetRow.unitPrice
              ? targetRow.unitPrice.mul(targetRow.quantity ?? new Big(0))
              : new Big(0)
          )}
        </StyledCell>
        {order && topic ? (
          <StyledPointerCell
            tabIndex={0}
            onKeyDown={(e) =>
              onClickOrKeyboardSelection(e, () =>
                onClickOrder(order.id, topic.id)
              )
            }
            onClick={(e) =>
              onClickOrKeyboardSelection(e, () =>
                onClickOrder(order.id, topic.id)
              )
            }
          >
            {orderCodeName}
          </StyledPointerCell>
        ) : (
          <StyledCell />
        )}
        {workPackage ? (
          <StyledPointerCell
            tabIndex={0}
            onKeyDown={(e) =>
              onClickOrKeyboardSelection(e, () =>
                onClickWorkPackage(workPackage.id)
              )
            }
            onClick={(e) =>
              onClickOrKeyboardSelection(e, () =>
                onClickWorkPackage(workPackage.id)
              )
            }
          >
            {workPackageCodeName}
          </StyledPointerCell>
        ) : (
          <StyledCell />
        )}
      </StyledSecondaryRow>
    </>
  );
};

type PrimaryRowAndCellProps = {
  depth?: number;
};

const StyledCell = styled(Cell)<PrimaryRowAndCellProps>`
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledReferencNoCellDiv = styled.div`
  margin-left: ${(props) => props.theme.margin[32]};
`;

const StyledPointerCell = styled(StyledCell)`
  cursor: pointer;
`;

const StyledSecondaryRow = styled(SecondaryRow)<PrimaryRowAndCellProps>`
  border: 2px solid ${(props) => props.theme.color.white};
  background: ${(props) => props.theme.color.graphiteB98};
  ${(props) => fontSizeCss(props.depth)}
`;

export default TargetRow;
