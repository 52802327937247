import styled, { css } from 'styled-components';

import { IconDown } from '../../assets';

/**
 * HTML label that positions itself above the select
 */
export const SelectLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

/**
 * HTML label that positions itself next to the select
 */
export const RowSelectLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type SelectProps = {
  invalid?: boolean;
};

/**
 * Native HTML select that is styled to have rounded corners, nicer
 * arrow icon, subtle background color and border
 */

export const Select = styled.select<SelectProps>`
  ${({ theme: { margin, color, fontSize, radius }, required, invalid }) =>
    css`
      margin-top: ${margin[8]};
      margin-bottom: ${margin[20]};

      border: 1px solid
        ${required && invalid ? color.errorNotification : color.dropdownBorder};
      border-radius: ${radius.small};

      padding: ${margin[8]} ${margin[16]};

      background: ${color.dropdownBg} url(${IconDown}) no-repeat;
      background-position: bottom 50% right 12px;

      font-size: ${fontSize.base};

      appearance: none;

      ${required
        ? css`
            :focus {
              outline-color: ${invalid
                ? `${color.errorNotification}`
                : `${color.modalTitleBarBackground}`};
            }
          `
        : ''}
    `}
`;

export const RowSelect = styled(Select)`
  margin-bottom: ${({ theme: { margin } }) => margin[8]};
  margin-left: ${({ theme: { margin } }) => margin[16]};
  width: 25rem;
`;
