import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';

import Big from 'big.js';
import styled, { css } from 'styled-components';
import * as uuid from 'uuid';

import {
  AnalysisRow as AnalysisRowType,
  AnalysisRowAttribute,
  requestAnalysisRowUpdate,
  requestDeleteAnalysisRow,
} from '../../../store/actions';

import useTxt from '../../../hooks/useTxt';

import {
  IconButton,
  BaseButtonWithUnderline,
} from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import EditableCell from '../../../components/EditableCell';

import { priceFormat } from '../../../utils/big';
import { maxLengthString } from '../../../utils/decoders';
import { useDebounce } from '../../../utils/hooks';

import { IconDelete } from '../../../assets/svg';

import { routes, useParams } from '../../../routes';
import { DynamicCell } from './DynamicCell';
import { ReceivedAnalysisModal } from './InvoiceActualCostModal';
import { OrderRowAnalysisModal } from './OrderRowModal';
import { tableColumns } from './TableHeader';
import { TargetRowAnalysisModal } from './TargetRowModal';

interface CellProps {
  name?: keyof typeof tableColumns;
}

const StyledCell = styled(Cell)<CellProps>`
  ${({ name }) =>
    name && tableColumns[name].position
      ? css`
          position: sticky;
          left: ${tableColumns[name].position};
          z-index: 1;
        `
      : ''}
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  background-color: white;
`;

// TODO: Fix these types
export interface EditableCellProps extends CellProps {
  onChange: any;
  codec: any;
  colSpan?: number;
}

const StyledEditableCell = styled(EditableCell)<EditableCellProps>`
  ${({ name }) =>
    name && tableColumns[name].position
      ? css`
          position: sticky;
          left: ${tableColumns[name].position};
          z-index: 1;
        `
      : ''}
  border-bottom: 1px solid ${(props) => props.theme.color.rowBorder};
  background-color: white;
`;

export type AnalysisRowProps = {
  id: string;
  value: string;
  code: string;
  orderRowsAmount: Big | null;
  paymentProgramRowsAmount: Big | null;
  targetRowsAmount: Big | null;
  arrivalsAmount: Big | null;
  attributeValues: AnalysisRowAttribute[];
};

export const AnalysisRow = ({
  code,
  value,
  orderRowsAmount,
  paymentProgramRowsAmount,
  targetRowsAmount,
  arrivalsAmount,
  attributeValues,
  id: analysisRowId,
}: AnalysisRowProps) => {
  const tooLongStringToolTipMsg10 = useTxt('validation.string.length.toolTip', {
    length: 10,
  });

  const tooLongStringToolTipMsg255 = useTxt(
    'validation.string.length.toolTip',
    {
      length: 255,
    }
  );

  const removeText = useTxt('analysis.button.remove');

  const dispatch = useDispatch();

  const { projectId, viewMode = 'view' } = useParams(routes.ANALYSIS);

  const [
    showOrderRowAnalysisModal,
    setShowOrderRowAnalysisModal,
  ] = React.useState(false);

  const [
    showTargetRowAnalysisModal,
    setShowTargetRowAnalysisModal,
  ] = React.useState(false);

  const [
    showInvoiceActualCostAnalysisModal,
    setShowInvoiceActualCostAnalysisModal,
  ] = React.useState(false);

  const [requestId] = React.useState(uuid.v4());

  const [update, setUpdate] = React.useState<
    undefined | Partial<AnalysisRowType>
  >(undefined);

  const updateRow = <K extends keyof AnalysisRowType>(key: K) => (
    val: AnalysisRowType[K]
  ) => setUpdate({ ...update, [key]: val });

  const onDeleteAnalysisRow = () => {
    dispatch(requestDeleteAnalysisRow({ requestId, projectId, analysisRowId }));
  };

  const debouncedUpdate = useDebounce(
    (data: undefined | Partial<AnalysisRowType>) => {
      if (!data) {
        return;
      }

      dispatch(
        requestAnalysisRowUpdate(data, {
          analysisRowId,
          requestId: `analysisRowUpdate-${analysisRowId}`,
          projectId,
        })
      );
      setUpdate(undefined);
    },
    1000
  );

  useUpdateEffect(() => {
    if (update) {
      debouncedUpdate(update);
    }
  }, [update]);

  return (
    <tr data-testid={`analysis-row-${analysisRowId}`}>
      {showOrderRowAnalysisModal ? (
        <OrderRowAnalysisModal
          analysisRowId={analysisRowId}
          onClose={() => setShowOrderRowAnalysisModal(false)}
        />
      ) : null}
      {showTargetRowAnalysisModal ? (
        <TargetRowAnalysisModal
          analysisRowId={analysisRowId}
          onClose={() => setShowTargetRowAnalysisModal(false)}
        />
      ) : null}
      {showInvoiceActualCostAnalysisModal ? (
        <ReceivedAnalysisModal
          analysisRowId={analysisRowId}
          onClose={() => setShowInvoiceActualCostAnalysisModal(false)}
        />
      ) : null}
      <StyledEditableCell
        align="right"
        initialValue={code}
        codec={maxLengthString(10)}
        onChange={updateRow('code')}
        route={routes.ANALYSIS}
        toolTipMsg={tooLongStringToolTipMsg10}
        onBlur={() => debouncedUpdate.flush()}
        name="code"
        colSpan={2}
      />
      <StyledEditableCell
        align="left"
        initialValue={value}
        codec={maxLengthString(255)}
        onChange={updateRow('value')}
        route={routes.ANALYSIS}
        toolTipMsg={tooLongStringToolTipMsg255}
        onBlur={() => debouncedUpdate.flush()}
        name="value"
      />

      <StyledCell align="right" name="paymentProgramRowsAmount">
        {paymentProgramRowsAmount
          ? priceFormat(new Big(paymentProgramRowsAmount || 0))
          : null}
      </StyledCell>
      <StyledCell align="right" name="orderRowsAmount">
        <BaseButtonWithUnderline
          onClick={() => setShowOrderRowAnalysisModal(true)}
        >
          {orderRowsAmount ? priceFormat(new Big(orderRowsAmount || 0)) : null}
        </BaseButtonWithUnderline>
      </StyledCell>
      <StyledCell align="right" name="targetRowsAmount">
        <BaseButtonWithUnderline
          onClick={() => setShowTargetRowAnalysisModal(true)}
        >
          {targetRowsAmount
            ? priceFormat(new Big(targetRowsAmount || 0))
            : null}
        </BaseButtonWithUnderline>
      </StyledCell>
      <StyledCell align="right" name="arrivalsAmount">
        <BaseButtonWithUnderline
          onClick={() => setShowInvoiceActualCostAnalysisModal(true)}
        >
          {arrivalsAmount ? priceFormat(new Big(arrivalsAmount || 0)) : null}
        </BaseButtonWithUnderline>
      </StyledCell>
      {attributeValues.map((attribute) => (
        <DynamicCell key={attribute.id} {...attribute} />
      ))}
      <StyledCell align="right" colSpan={2}>
        {viewMode === 'edit' ? (
          <IconButton
            icon={IconDelete}
            onClick={onDeleteAnalysisRow}
            aria-label={removeText}
          />
        ) : null}
      </StyledCell>
    </tr>
  );
};
