import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { getSelectedTargetRowsForOrder } from '../../../../store/reducers/target/selection';
import {
  getIsOuterBarOpen,
  getSelectedOrderRowsForOrder,
} from '../../../../store/reducers/ui';

import * as Actions from '../../../../store/actions';

import { EditViewModeOptions } from '../../../../types/general';

import useRouteParams from '../../../../hooks/useRouteParams';
import useChangeViewMode from '../../hooks/useChangeViewMode';

import { PrimaryButton } from '../../../../components/Buttons';
import {
  OrderViewContent,
  OrderViewFooter,
  OrderViewEmptyDiv,
} from '../../../../components/Containers';
import { TableSeparateBorderCollapse } from '../../../../components/Table';
import {
  ToolBeltContainer,
  ToolBeltButton,
} from '../../../../components/ToolBelt';
import Txt from '../../../../components/Txt';
import { OrderTableHeader } from '../../components/OrderTableHeader';
import Summary from '../../components/Summary';
import { AddTargetRowsModal } from '../../components/Target/AddTargetRowsModal';
import MoveTargetModal from '../../components/Target/MoveModal';
import { SplitTargetRowsModal } from '../../components/Target/TargetSplitModal';
import Topics from '../../components/Topics';
import MoveModal from './components/MoveModal';

import CAN, {
  CaslProjectRequestParams,
} from '../../../../utils/caslUserPermissions';
import { emptyTopic } from '../../../../utils/general';

import {
  IconDelete,
  IconMove,
  IconAdd,
  IconPlus,
  IconSplitRow,
} from '../../../../assets/svg';

const EditModeWrapper = () => {
  const changeViewMode = useChangeViewMode();
  const changeViewModeToNormal = () => changeViewMode('normal');
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const { projectId, orderId, showTargetRows } = useRouteParams();

  const viewModeOptions: EditViewModeOptions = {
    type: 'edit',
    showTargetRows,
  };
  const dispatch = useDispatch();

  const onCreateTopic = () => {
    dispatch(Actions.createTopic(emptyTopic(orderId)));
  };

  const tableRef = React.useRef<HTMLTableElement>(null);

  const selectedOrderRows = useSelector(getSelectedOrderRowsForOrder(orderId));

  const isMovingDisabled = selectedOrderRows.length === 0;

  const selectedTargetRows = useSelector(
    getSelectedTargetRowsForOrder(orderId)
  );

  const isTargetMovingDisabled =
    selectedTargetRows.length === 0 || selectedOrderRows.length > 0;

  const [isAddTargetRowsVisible, setIsAddTargetRowsVisible] = React.useState(
    false
  );

  const [
    isSplitTargetRowsVisible,
    setIsSplitTargetRowsVisible,
  ] = React.useState(false);

  const [
    isMoveTargetModalVisible,
    setMoveTargetModalVisibility,
  ] = React.useState(false);

  const onAddTargetRowsClick = () => {
    setIsAddTargetRowsVisible(true);
  };

  const onSplitTargetRowsClick = () => {
    setIsSplitTargetRowsVisible(true);
  };

  const ability = new CaslProjectRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const [isMoveModalVisible, setMoveModalVisibility] = React.useState(false);

  return (
    <>
      <OrderViewContent outerBarOpen={outerBarOpen}>
        {isMoveModalVisible ? (
          <MoveModal onClose={() => setMoveModalVisibility(false)} />
        ) : null}
        {isMoveTargetModalVisible ? (
          <MoveTargetModal
            onClose={() => setMoveTargetModalVisibility(false)}
          />
        ) : null}
        {isAddTargetRowsVisible ? (
          <AddTargetRowsModal
            onClose={() => setIsAddTargetRowsVisible(false)}
          />
        ) : null}
        {isSplitTargetRowsVisible ? (
          <SplitTargetRowsModal
            onClose={(success) => {
              setIsSplitTargetRowsVisible(false);

              if (success === true) {
                changeViewModeToNormal();
              }
            }}
          />
        ) : null}
        <ToolBeltContainer>
          {showTargetRows ? (
            <>
              <ToolBeltButton
                icon={StyledIconSplitRow}
                onClick={() => onSplitTargetRowsClick()}
                disabled={!allowedUser || selectedTargetRows.length === 0}
              >
                <Txt id="order.targetMode.toolBelt.splitTargetRows" />
              </ToolBeltButton>
              <ToolBeltButton
                icon={IconPlus}
                onClick={() => onAddTargetRowsClick()}
                disabled={!allowedUser}
              >
                <Txt id="order.targetMode.toolBelt.addTargetRows" />
              </ToolBeltButton>
              <ToolBeltButton
                icon={IconMove}
                disabled={isTargetMovingDisabled || !allowedUser}
                onClick={() => setMoveTargetModalVisibility(true)}
              >
                <Txt
                  id={
                    selectedTargetRows.length === 1
                      ? 'order.targetMode.toolBelt.move.one'
                      : 'order.targetMode.toolBelt.move'
                  }
                  values={{ count: selectedTargetRows.length.toString() }}
                />
              </ToolBeltButton>
            </>
          ) : null}
          <ToolBeltButton icon={IconDelete} disabled>
            <Txt id="order.editMode.toolBelt.remove" />
          </ToolBeltButton>
          <ToolBeltButton
            icon={IconMove}
            disabled={isMovingDisabled}
            onClick={() => setMoveModalVisibility(true)}
          >
            <Txt
              id={
                selectedOrderRows.length === 1
                  ? 'order.editMode.toolBelt.move.one'
                  : 'order.editMode.toolBelt.move'
              }
              values={{ count: selectedOrderRows.length.toString() }}
            />
          </ToolBeltButton>
          <ToolBeltButton icon={IconAdd} onClick={onCreateTopic}>
            <Txt id="order.editMode.toolBelt.add" />
          </ToolBeltButton>
        </ToolBeltContainer>
        <Summary viewMode="edit" />
        <StyledTable outerBarOpen={outerBarOpen} ref={tableRef}>
          <OrderTableHeader orderViewMode={viewModeOptions} orderId={orderId} />
          <Topics
            projectId={projectId}
            orderId={orderId}
            viewModeOptions={viewModeOptions}
            ref={tableRef}
          />
        </StyledTable>
        <OrderViewEmptyDiv />
      </OrderViewContent>
      <OrderViewFooter>
        <PrimaryButton onClick={changeViewModeToNormal}>
          <Txt id="order.editMode.saveAndExit" />
        </PrimaryButton>
      </OrderViewFooter>
    </>
  );
};

const StyledIconSplitRow = styled(IconSplitRow)`
  width: 16px;
  height: 16px;
`;

// table-layout: fixed prevents usage of min-width for th
const StyledTable = styled(TableSeparateBorderCollapse)<{
  outerBarOpen: boolean;
}>`
  table-layout: ${(props) => (props.outerBarOpen ? 'auto' : 'fixed')};
`;

export default EditModeWrapper;
