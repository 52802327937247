import React from 'react';
import { useSelector } from 'react-redux';

import { orderBy } from 'lodash';
import styled from 'styled-components';

import { getAnalysisGroupsForProject } from '../../store/reducers/analysis/group';
import { getActiveProjectId } from '../../store/reducers/ui';
import { Selector } from '../../store/reducers/utils';

import { requestAnalysisGroups } from '../../store/actions';
import { RevenueLinkRequest } from '../../store/actions/analysis/revenueLinkage';
import { AnalysisRow } from '../../store/actions/analysis/row';

import useRemoteData from '../../hooks/useRemoteData';

import * as remoteData from '../../utils/remoteData';
import { Thunk } from '../../utils/thunk';

import RemoteData from '../RemoteData';
import Row from './Row';

const filterRows = (filter: string) => ({ code, value }: AnalysisRow) => {
  if (filter === '') {
    return true;
  }

  const lcFilter = filter.toLowerCase();
  const lcCode = code.toLowerCase();
  const lcValue = value.toLowerCase();

  return `${lcCode} ${lcValue}`.includes(lcFilter);
};

type AnalysisListProps = {
  filter: string;
  linkedEntityId: string;
  existingLinks: string[];
  // close: () => void | undefined;
  selector: Selector<remoteData.RemoteData<AnalysisRow[]>>;
  fetchData: Thunk;
  linker: (params: RevenueLinkRequest) => Thunk;
  unlinker: (params: RevenueLinkRequest) => Thunk;
};

const AnalysisRowList = ({
  filter,
  linkedEntityId,
  existingLinks,
  // close,
  selector,
  fetchData,
  linker,
  unlinker,
}: AnalysisListProps) => {
  const rows = useSelector(selector);
  const projectId = useSelector(getActiveProjectId) ?? '';

  const analysisDefinitions =
    useRemoteData(
      getAnalysisGroupsForProject(projectId),
      requestAnalysisGroups({ projectId })
    ) ?? [];

  return (
    <Container data-testid="analysis-linker-row-list">
      <RemoteData data={rows} fetchData={fetchData}>
        {(fetchedRows) => {
          const selectableRows = fetchedRows.filter(filterRows(filter));

          const sortedRows = orderBy(
            selectableRows,
            [
              (row) => row.definitionId.toLowerCase(),
              (row) => row.code.toLowerCase(),
              (row) => row.value.toLowerCase(),
            ],
            ['asc', 'asc', 'asc']
          );

          const nestedGroupedRows =
            sortedRows.length > 0
              ? sortedRows.reduce<Record<string, AnalysisRow[]>>((acc, row) => {
                  if (!acc[row.definitionId]) {
                    acc[row.definitionId] = [];
                  }

                  acc[row.definitionId].push(row);

                  return acc;
                }, {})
              : {};

          return (
            <>
              {Object.values(nestedGroupedRows).map((groupedRows, index) => {
                const defination = analysisDefinitions.find(
                  (def) => def.id === groupedRows[0].definitionId
                );

                const groupHeader = defination?.name || 'No group';

                return (
                  <React.Fragment key={groupedRows[0].definitionId || index}>
                    <Header
                      key={groupHeader !== 'No group' ? groupHeader : index}
                    >
                      {groupHeader}
                    </Header>
                    {groupedRows.map((row) => (
                      <Row
                        key={row.id}
                        analysisRow={row}
                        linkedEntityId={linkedEntityId}
                        existingLinks={existingLinks}
                        // close={close}
                        linker={linker}
                        unlinker={unlinker}
                      />
                    ))}
                  </React.Fragment>
                );
              })}
            </>
          );
        }}
      </RemoteData>
    </Container>
  );
};

export default AnalysisRowList;

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.graphiteB76};

  padding-bottom: 1.5rem;

  max-height: 22rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
`;

const Header = styled.p`
  padding: 1rem 1rem;

  width: 100%;

  align-items: center;
  display: flex;

  background-color: lightgray;

  font-size: 10px;
  font-weight: bold;

  overflow: hidden;
`;
