import { Reducer } from 'redux';

import { ID } from '@customtypes/general';

import { APIActualCostAttachmentFile } from '../../types/api';

import * as api from '../../utils/api';
import normalizeBy from '../../utils/normalizeBy';
import * as remoteData from '../../utils/remoteData';
import { Selector } from './utils';

import { ActionTypes } from '../actionTypes';

type Err = api.BackendError | undefined;

type GetActualCostImageRequestsState = Partial<
  Record<string, remoteData.RemoteData<string, Err>>
>;

export type ActualCostAttachmentFilesState = {
  requests: Partial<Record<string, remoteData.RemoteData<undefined, Err>>>;
  data: Record<ID, APIActualCostAttachmentFile>;
  actualCostImageRequests: GetActualCostImageRequestsState;
};

const initialState: ActualCostAttachmentFilesState = {
  requests: {},
  data: {},
  actualCostImageRequests: {},
};

const actualCostAttachmentFilesReducer: Reducer<
  ActualCostAttachmentFilesState,
  ActionTypes
> = (state = initialState, action): ActualCostAttachmentFilesState => {
  switch (action.type) {
    case 'GET_ACTUAL_COST_ATTACHMENT_FILES_STARTED': {
      const { orderId } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.loading };

      return {
        ...state,
        requests,
      };
    }
    case 'GET_ACTUAL_COST_ATTACHMENT_FILES_FAILURE': {
      const { orderId, error } = action.payload;
      const requests = { ...state.requests, [orderId]: remoteData.fail(error) };

      return { ...state, requests };
    }
    case 'GET_ACTUAL_COST_ATTACHMENT_FILES_SUCCESS': {
      const { orderId, actualCostAttachmentFiles } = action.payload;

      const requests = {
        ...state.requests,
        [orderId]: remoteData.succeed(undefined),
      };

      const data = {
        ...state.data,
        ...normalizeBy('id', actualCostAttachmentFiles),
      };

      return {
        ...state,
        requests,
        data,
      };
    }
    case 'GET_ACTUAL_COST_IMAGE_FILE_STARTED': {
      const { actualCostId } = action.payload;

      const newState = {
        ...state.actualCostImageRequests,
        [actualCostId]: remoteData.loading,
      };

      return {
        ...state,
        actualCostImageRequests: newState,
      };
    }
    case 'GET_ACTUAL_COST_IMAGE_FILE_SUCCESS': {
      const { actualCostId, imageUrl } = action.payload;

      const newState = {
        ...state.actualCostImageRequests,
        [actualCostId]: remoteData.succeed(imageUrl),
      };

      return {
        ...state,
        actualCostImageRequests: newState,
      };
    }
    case 'GET_ACTUAL_COST_IMAGE_FILE_FAILURE': {
      const { actualCostId } = action.payload;

      const newState = {
        ...state.actualCostImageRequests,
        [actualCostId]: remoteData.fail(action.payload.error),
      };

      return {
        ...state,
        actualCostImageRequests: newState,
      };
    }
    default:
      return state;
  }
};

export function getActualCostAttachmentFilesRequest(
  requestId: string
): Selector<remoteData.RemoteAction> {
  return ({
    actualCostAttachmentFiles: {
      requests: { [requestId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export function getActualCostImageRequest(
  actualCostId: string
): Selector<remoteData.RemoteData<string>> {
  return ({
    actualCostAttachmentFiles: {
      actualCostImageRequests: { [actualCostId]: request },
    },
  }) => request ?? remoteData.notAsked;
}

export default actualCostAttachmentFilesReducer;
