import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getOrder } from '../../store/reducers/order/order';
import { getOrderRows } from '../../store/reducers/orderRow';
import { getOrderTopics } from '../../store/reducers/topic';
import { getIsOuterBarOpen } from '../../store/reducers/ui';

import * as Actions from '../../store/actions';
import {
  setLastActiveProcurementArea,
  uiStateProcurementAreaOpened,
} from '../../store/actions/ui';

import useRemoteData, { useRemoteDataKind } from '../../hooks/useRemoteData';
import useRouteParams from '../../hooks/useRouteParams';

import { GlobalLoadingSpinner } from '../../components/Loading';
import OrderPageTop from './components/OrderPageTop';

import assertNever from '../../utils/assertNever';

import { generateUrl, routes } from '../../routes';
import { CommentModal } from './CommentModal';
import EditModeWrapper from './ViewModeWrappers/Edit';
import NormalModeWrapper from './ViewModeWrappers/NormalModeWrapper';
import ReceiveModeWrapper from './ViewModeWrappers/Receive';

const OrderView = () => {
  const { projectId, orderId, viewMode } = useRouteParams();
  const outerBarOpen = useSelector(getIsOuterBarOpen());

  const dispatch = useDispatch();

  const history = useHistory();

  const order = useRemoteData(
    getOrder({ orderId, projectId }),
    Actions.fetchOrdersForProject(projectId)
  );

  const procurementAreaId = useMemo(() => order?.procurementAreaId, [order]);

  useEffect(() => {
    dispatch(Actions.fetchArrivalsForOrder(orderId));
    dispatch(Actions.fetchArrivalRowsForOrder(orderId));
    dispatch(Actions.fetchInvoiceHeadersForOrder(orderId));
    dispatch(Actions.fetchTasksForOrder(orderId));
    dispatch(Actions.requestInvoiceAttachmentsForOrder(orderId));
    dispatch(Actions.requestInvoiceAttachmentFilesForOrder(orderId));
    dispatch(Actions.fetchInvoiceLinesForOrder(orderId));
    dispatch(Actions.fetchActualCostsForOrder(orderId));
    dispatch(Actions.requestActualCostAttachmentsForOrder(orderId));
    dispatch(Actions.requestActualCostAttachmentFilesForOrder(orderId));
    dispatch(Actions.getDropDowns);

    if (procurementAreaId) {
      dispatch(setLastActiveProcurementArea(procurementAreaId));
      dispatch(uiStateProcurementAreaOpened(procurementAreaId));
    }
  }, [orderId, dispatch, projectId, procurementAreaId]);

  const topics =
    useRemoteDataKind(
      getOrderTopics(orderId),
      Actions.fetchTopicsForOrder(orderId)
    ) === 'Success';

  const orderRows =
    useRemoteDataKind(
      getOrderRows({ orderId }),
      Actions.fetchOrderRowsForOrder(orderId)
    ) === 'Success';

  if (!(order && topics && orderRows)) {
    return <GlobalLoadingSpinner />;
  }

  const renderViewModeWrapper = () => {
    switch (viewMode) {
      case 'normal':
        return <NormalModeWrapper />;
      case 'edit':
        return <EditModeWrapper />;
      case 'receive':
        return <ReceiveModeWrapper />;
      case 'comments':
        return (
          <CommentModal
            orderId={orderId}
            onClose={() =>
              history.push(
                generateUrl({
                  route: routes.ORDER,
                  projectId,
                  orderId,
                })
              )
            }
          />
        );
      default:
        throw assertNever(viewMode);
    }
  };

  return (
    <>
      <StickyHeaderContainer outerBarOpen={outerBarOpen}>
        <OrderPageTop />
      </StickyHeaderContainer>
      {renderViewModeWrapper()}
    </>
  );
};

type OrderViewProps = {
  outerBarOpen?: boolean;
};

const StickyHeaderContainer = styled.div<OrderViewProps>`
  position: sticky;
  top: 0;

  padding: 1rem ${(props) => (props.outerBarOpen ? '1rem' : '2.5rem')} 0 2.5rem;

  background-color: ${({ theme }) => theme.color.white};

  z-index: 3;
`;

export default OrderView;
