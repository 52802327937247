import React from 'react';

import { OptionData } from 'rc-select/lib/interface';
import styled from 'styled-components';

import useTxt from '../../../hooks/useTxt';

import DropDownSelect from '../../../components/DropDownSelect';
import { MuiLabel } from '../../../components/Input/TextInput';

import { isDefined } from '../../../utils/general';

import { IconTriangleDown } from '../../../assets/svg';

import defaultTheme from '../../../styles/theme';
import { ExpandCollapseButton } from './NewScheduleTree';

type WorkSectionClassDropdownProps = {
  onSelectionChange: (selection: string) => void;
  workSectionClassOptions: {
    value: string;
    label: string;
    depth: number;
    parents: string[];
    lowest: boolean;
  }[];
  initialValue: string | null | undefined;
};

const WorkSectionClassDropdown = ({
  onSelectionChange,
  workSectionClassOptions,
  initialValue,
}: WorkSectionClassDropdownProps) => {
  const [closedValues, setClosedValues] = React.useState<string[]>([]);

  const workSectionClassLabel = useTxt('common.workSectionClass');

  const noSelectionText = useTxt('common.noSelection');

  const options: OptionData[] = workSectionClassOptions
    .filter(
      (data) => !data.parents.some((parent) => closedValues.includes(parent))
    )
    .map((data) => {
      const isOpen = !closedValues.includes(data.value);

      const onExpandCollapse = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (isOpen) {
          setClosedValues([...closedValues, data.value]);
        } else {
          setClosedValues(closedValues.filter((value) => value !== data.value));
        }
      };

      const allParentsLabels = data.parents
        .map((parent) => {
          const parentData = workSectionClassOptions.find(
            (option) => option.value === parent
          );

          return parentData?.label;
        })
        .filter(isDefined);

      const allChildrenLabels = workSectionClassOptions
        .filter((option) => option.parents.includes(data.value))
        .map((option) => option.label);

      return {
        value: data.value,
        label: (
          <LabelDiv depth={data.depth}>
            {!data.lowest ? (
              <ExpandCollapseButton
                icon={isOpen ? IconTriangleDown : StyledIconRight}
                customSize="12px"
                onClick={onExpandCollapse}
              />
            ) : (
              <EmptyDiv />
            )}
            <HiddenOverflowDiv>
              <HiddenOverflowSpan>{data.label}</HiddenOverflowSpan>
            </HiddenOverflowDiv>
          </LabelDiv>
        ),
        title: `${data.label}`,
        filter: `${[...allParentsLabels.reverse(), data.label].join(
          ' > '
        )} > ${allChildrenLabels.join(' + ')}`,
      };
    });

  const emptyOption = {
    value: 'NONE',
    label: noSelectionText,
    title: noSelectionText,
    filter: noSelectionText,
  };

  return (
    <LabelSelectContainer>
      <MuiLabel invalid={false}>{workSectionClassLabel}</MuiLabel>
      <StyledDropDownSelect
        options={[emptyOption, ...options]}
        additionalContainerStyles={additionalContainerStyling}
        onChange={onSelectionChange}
        optionFilterProp="filter"
        optionLabelProp="title"
        value={initialValue ?? undefined}
      />
    </LabelSelectContainer>
  );
};

export default WorkSectionClassDropdown;

type LabelDivProps = {
  depth?: number;
};

const LabelDiv = styled.div<LabelDivProps>`
  padding-left: ${({ depth }) => (depth ? `${depth}rem` : 0)};
  padding-right: ${(props) => props.theme.margin[8]};

  height: ${(props) => props.theme.margin[32]};

  display: flex;
  align-items: center;
`;

const StyledIconRight = styled(IconTriangleDown)`
  transform: rotate(-90deg);
`;

const additionalContainerStyling: React.CSSProperties = {
  width: '100%',
  padding: `${defaultTheme.margin[4]} ${defaultTheme.margin[4]} ${defaultTheme.margin[4]} ${defaultTheme.margin[16]}`,
  height: defaultTheme.margin[36],
  backgroundColor: 'transparent',
  margin: `${defaultTheme.margin[4]} 0 ${defaultTheme.margin[4]} ${defaultTheme.margin[16]}`,
};

const StyledDropDownSelect = styled(DropDownSelect)`
  margin-right: ${(props) => props.theme.margin[32]};
`;

const HiddenOverflowSpan = styled.span`
  width: 100%;

  display: inline-block;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
`;

const HiddenOverflowDiv = styled.div`
  display: inline-grid;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
`;

const LabelSelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const EmptyDiv = styled.div`
  height: 20px;
  width: 20px;
  display: inline-block;
`;
