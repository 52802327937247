import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { getProjectById } from '../../store/reducers/project';
import {
  getWorkPackageById,
  selectProjectWorkPackages,
} from '../../store/reducers/workPackage';

import * as Actions from '../../store/actions';

import { IconTextButton } from '../../components/Buttons';
import Header from '../../components/Header';
import TabIconTextButton from '../../components/Navigation/TabIconTextButton';
import ProjectInfo from '../../components/ProjectInfo';
import Txt from '../../components/Txt';

import CAN, {
  CaslWorkPackageRequestParams,
} from '../../utils/caslUserPermissions';
import { isSuccess } from '../../utils/remoteData';

import { IconArrowLeft, IconCogwheel } from '../../assets/svg';

import { generateUrl, routes, useParams } from '../../routes';
import EditWorkSectionModal from './EditWorkSection';
import NewEditWorkSectionModal from './EditWorkSection/New';
import SectionTable from './SectionTable';
import WorkSectionGraph from './WorkSectionGraph';

const WorkSectionExpanded = () => {
  const dispatch = useDispatch();
  const [isRendered, setRendered] = useState<boolean>(false);

  const { workPackageId, projectId, viewMode } = useParams(
    routes.WORKSECTION_EXPANDED
  );

  const project = useSelector(getProjectById(projectId));

  const workPackageRequestState = isSuccess(
    useSelector(selectProjectWorkPackages(projectId))
  );

  // Fetch project-related data here every time project or workPackage changes.
  useEffect(() => {
    dispatch(Actions.fetchWorkSectionRows({ projectId, workPackageId }));
    dispatch(Actions.fetchWorkPackagesForProject(projectId));
    dispatch(Actions.getWorkPackageTimelineForProject({ projectId }));
    dispatch(Actions.getWorkPackageGroupTimelineForProject({ projectId }));
    dispatch(Actions.getProjectTimelineForProject({ projectId }));
  }, [projectId, workPackageId, dispatch]);

  useLayoutEffect(() => {
    setRendered(true);
  }, []);

  const { code = '', name = '' } =
    useSelector(getWorkPackageById(workPackageId ?? '')) ?? {};

  const history = useHistory();

  const navigateBackToWorksectionList = () => {
    history.push(generateUrl({ route: routes.WORKSECTIONS, projectId }));
  };

  const ability = new CaslWorkPackageRequestParams(projectId);
  const allowedUser = CAN('write', ability);

  const toggleEditMode = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.stopPropagation();

    if (!viewMode) {
      history.push(
        generateUrl({
          route: routes.WORKSECTION_EXPANDED,
          projectId,
          viewMode: 'edit',
          workPackageId,
        })
      );
    } else {
      history.push(
        generateUrl({
          route: routes.WORKSECTION_EXPANDED,
          projectId,
          workPackageId,
        })
      );
    }
  };

  const editWorkPackage = () => {
    const nextGenInUse = !!project?.nextgenDefaultScheduleViewId;

    if (nextGenInUse && viewMode) {
      return 'newEditWorkPackage';
    }

    if (viewMode) {
      return 'editWorkPackage';
    }

    return null;
  };

  return (
    <Container>
      <BackButton
        icon={IconArrowLeft}
        onClick={() => navigateBackToWorksectionList()}
      >
        <Txt id="worksectionDetails.back" component="b" />
      </BackButton>
      <StyledHeader>
        <Txt id="worksectionDetails.header" values={{ code, name }} />
      </StyledHeader>
      <ProjectInfo projectId={projectId} />
      <ButtonContainer>
        <TabIconTextButton
          onClick={toggleEditMode}
          isActive={!!viewMode}
          disabled={!allowedUser || !workPackageRequestState || !isRendered}
          icon={IconCogwheel}
          id="worksectionDetails.actionButton.info"
        />
      </ButtonContainer>
      {editWorkPackage() === 'editWorkPackage' ? (
        <EditWorkSectionModal
          onClose={() => toggleEditMode()}
          workPackageId={workPackageId}
          headerInfo={{ code, name }}
        />
      ) : null}
      {editWorkPackage() === 'newEditWorkPackage' ? (
        <NewEditWorkSectionModal
          onClose={toggleEditMode}
          workPackageId={workPackageId}
          headerInfo={{ code, name }}
        />
      ) : null}
      <WorkSectionGraph workPackageId={workPackageId} />
      <StyledTitle>
        <Txt id="navigation.project" />
      </StyledTitle>
      <SectionTable projectId={projectId} workPackageId={workPackageId} />
    </Container>
  );
};

const Container = styled.div`
  margin: ${({ theme }) => theme.margin[24]} ${({ theme }) => theme.margin[40]};
`;

const StyledTitle = styled.h3`
  margin-bottom: ${({ theme }) => theme.margin[24]};
  margin-left: 0;
  border-top: 1px solid ${({ theme }) => theme.color.rowBorder};
  padding-top: ${({ theme }) => theme.margin[24]};
`;

const ButtonContainer = styled.div`
  height: ${({ theme: { margin } }) => margin[40]};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

const BackButton = styled(IconTextButton)`
  margin-bottom: ${({ theme: { margin } }) => margin[8]};
`;

const StyledHeader = styled(Header)`
  margin-bottom: ${({ theme: { margin } }) => margin[8]};
`;

export default WorkSectionExpanded;
