import React from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';

import { ApexOptions } from 'apexcharts';
import Big from 'big.js';
import styled, { css } from 'styled-components';

import { getProjectPoCMonths } from '../../../store/reducers/schedule/projectTimeline';
import {
  getWorkPackageCurrentPeriodReceivedTimelineEntries,
  getWorkPackageSnapshotReceivedTimelineEntries,
} from '../../../store/reducers/schedule/workPackageReceivedTimeline';
import {
  getWorkPackagePlannedPoCEntries,
  getWorkPackageSnapshotPoCEntries,
  getWorkPackageCurrentPeriodActualPoC,
} from '../../../store/reducers/schedule/workPackageTimeline';
import { getSnapshots, Snapshot } from '../../../store/reducers/snapshot';
import { getWorkPackageById } from '../../../store/reducers/workPackage';

import {
  fetchSnapshots,
  getWorkPackageReceivedTimelineEntries,
} from '../../../store/actions';
import { getProjectTimelineForProject } from '../../../store/actions/schedule/projectTimeline';
import { getWorkPackageTimelineForProject } from '../../../store/actions/schedule/workPackageTimeline';

import useRemoteData from '../../../hooks/useRemoteData';
import useStateRef from '../../../hooks/useStateRef';
import useTxt from '../../../hooks/useTxt';

import * as big from '../../../utils/big';
import { dateFormat } from '../../../utils/format';
import { getEOMonth, isNotNull } from '../../../utils/general';
import { withDefault } from '../../../utils/remoteData';

import { routes, useParams } from '../../../routes';
import theme from '../../../styles/theme';
import WorkPackageSummary from './WorkPackageSummary';

interface WorkSectionGraphProps {
  workPackageId: string;
}

interface ChartData {
  x: Date;
  y: number;
}

interface SeriesData {
  name: string;
  data: ChartData[];
}

interface TimeScaleArrayEntry {
  position: number;
  value: number;
  unit: string;
  year: number;
  month: number;
}

const WorkSectionGraph = (props: WorkSectionGraphProps) => {
  const { projectId } = useParams(routes.WORKSECTION_EXPANDED);
  const dispatch = useDispatch();

  const { workPackageId } = props;

  const workPackage = useSelector(getWorkPackageById(workPackageId ?? ''));

  const [todayPosition, setRef] = useStateRef<number>((node) => {
    if (node?.chart) {
      const today = new Date();

      const timeScaleArray: TimeScaleArrayEntry[] =
        node.chart?.timeScale.timeScaleArray ?? [];

      const todayPos =
        timeScaleArray.findIndex(
          (entry) =>
            entry.month === today.getMonth() &&
            entry.year === today.getFullYear()
        ) + 1;

      return todayPos ?? null;
    }

    return null;
  });

  const today = new Date();

  const scheduleInUse = workPackage
    ? workPackage?.scheduleTaskIds.length > 0
    : false;

  // always re-fetch data when rendering
  React.useEffect(() => {
    dispatch(getWorkPackageReceivedTimelineEntries({ workPackageId }));
  }, [dispatch, workPackageId]);

  const actualPoC = useRemoteData(
    getWorkPackageCurrentPeriodActualPoC({
      workPackageId,
      projectId,
    }),
    getWorkPackageTimelineForProject({ projectId })
  );

  const percentageOfCompletion = actualPoC
    ? actualPoC.percentageOfCompletion
    : new Big(0);

  const predictionTotal = workPackage?.predictionTotal ?? new Big(0);

  const pocAsEuros =
    (percentageOfCompletion.toNumber() * predictionTotal.toNumber()) / 100;

  const months =
    useRemoteData(
      getProjectPoCMonths({
        projectId,
      }),
      getProjectTimelineForProject({ projectId })
    ) ?? [];

  const snapshotRows =
    useRemoteData(getSnapshots(projectId), fetchSnapshots(projectId)) ?? [];

  const latestByMonth = snapshotRows.reduce(
    (acc: Record<string, Snapshot>, curr) => {
      const monthYear = `${curr.createdAt.getFullYear()}-${curr.createdAt.getMonth()}`;

      if (!acc[monthYear] || curr.createdAt > acc[monthYear].createdAt) {
        acc[monthYear] = curr;
      }

      return acc;
    },
    {}
  );

  const result = Object.values(latestByMonth).map((row: any) => row.id);

  const snapshotIdsAndDescriptions = result.map((id) => {
    const snapshot = snapshotRows.find((row) => row.id === id);

    const description = snapshot ? snapshot.description : '';
    const createdAt = snapshot ? snapshot.createdAt : null;

    return { id, description, createdAt };
  });

  const snapshotCreatedAt = snapshotIdsAndDescriptions
    .map((row) => row.createdAt)
    .filter(isNotNull);

  const acceptedWorksSnapshotData = withDefault(
    useSelector(getWorkPackageSnapshotReceivedTimelineEntries(workPackageId)),
    []
  );

  const acceptedWorksCurrentPeriodData = withDefault(
    useSelector(
      getWorkPackageCurrentPeriodReceivedTimelineEntries(workPackageId)
    ),
    []
  );

  const plannedEntries =
    useRemoteData(
      getWorkPackagePlannedPoCEntries({
        projectId,
        workPackageId,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const snapshotEntries =
    useRemoteData(
      getWorkPackageSnapshotPoCEntries({
        projectId,
        workPackageId,
      }),
      getWorkPackageTimelineForProject({ projectId })
    ) ?? [];

  const xAxis = snapshotCreatedAt.concat(months);

  const acceptedWorksSnapshotSeries = snapshotIdsAndDescriptions.map(
    (current) => {
      const value = acceptedWorksSnapshotData.find(
        (row) => row.snapshotId === current.id
      );

      if (!value) {
        return null;
      }

      return {
        x: value.date,
        y:
          (value.percentageOfCompletion.toNumber() *
            predictionTotal.toNumber()) /
          100,
      };
    }
  );

  const acceptedWorksCurrentAndFutureSeries = months.map((current) => {
    const value = acceptedWorksCurrentPeriodData.find(
      (entry) =>
        entry.date.getMonth() === current.getMonth() &&
        entry.date.getFullYear() === current.getFullYear()
    );

    if (!value) {
      return null;
    }

    return {
      x: value.date,
      y:
        (value.percentageOfCompletion.toNumber() * predictionTotal.toNumber()) /
        100,
    };
  });

  const acceptedWorksSeries = acceptedWorksSnapshotSeries
    .concat(acceptedWorksCurrentAndFutureSeries)
    .filter(isNotNull);

  const pocSnapshotSeries = snapshotIdsAndDescriptions
    .map((snapshot) => {
      const value = snapshotEntries.find(
        (row) => row.snapshotId === snapshot.id
      );

      if (!value) {
        return null;
      }

      return {
        x: value?.date,
        y:
          (value?.percentageOfCompletion.toNumber() *
            predictionTotal.toNumber()) /
          100,
      };
    })
    .filter(isNotNull);

  const pocCurrentAndFutureSeries = months
    .map((current) => {
      const value = plannedEntries.find(
        (entry) =>
          entry.date.getMonth() === current.getMonth() &&
          entry.date.getFullYear() === current.getFullYear()
      );

      if (!value) {
        return null;
      }

      return {
        x: value.date,
        y:
          (value.percentageOfCompletion.toNumber() *
            predictionTotal.toNumber()) /
          100,
      };
    })
    .filter(isNotNull);

  const targetTxt = useTxt('worksection.table.header.targetTotal');
  const acceptedTxt = useTxt('common.received');
  const plannedTxt = useTxt('common.planned');
  const reporterTxt = useTxt('common.reported');
  const currentAcceptedTxt = useTxt('common.currentAccepted');

  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );

  const lastDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  );

  // This is used for highlighting (blue color) x-axis current month
  const countMonthsBetweenDates = (
    startDate: Date | null,
    endDate: Date | null
  ): Date[] => {
    if (!startDate || !endDate) {
      return [];
    }

    const axisMonths: Date[] = [getEOMonth(startDate)]; // add start date here

    const tempDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate()
    );

    while (tempDate < endDate) {
      axisMonths.push(getEOMonth(tempDate));
      tempDate.setMonth(tempDate.getMonth() + 1);
    }

    return [...axisMonths, getEOMonth(endDate)];
  };

  const xValues = [
    ...pocSnapshotSeries,
    ...pocCurrentAndFutureSeries,
    ...acceptedWorksSeries,
    { x: firstDayOfCurrentMonth, y: 0 },
  ].map((entry) => entry.x.getTime());

  const yValues = [
    ...pocSnapshotSeries,
    ...pocCurrentAndFutureSeries,
    ...acceptedWorksSeries,
  ].map((entry) => entry.y);

  const notEmptyxValues =
    xValues.length === 0 ? [new Date().getTime()] : xValues;

  const minX = new Date(Math.min(...notEmptyxValues));
  const maxX = new Date(Math.max(...xValues));

  const minY = Math.min(...yValues);

  const minXPreviousDate = minX.setMonth(minX.getMonth() - 1);

  const targetSeries = countMonthsBetweenDates(minX || null, maxX || null).map(
    (date) => ({
      x: date,
      y: workPackage?.targetTotal.toNumber(),
    })
  );

  const chartSeries = [
    {
      name: targetTxt,
      data: targetSeries,
    },
    {
      name: reporterTxt,
      data: pocSnapshotSeries,
    },
    {
      name: plannedTxt,
      data: pocCurrentAndFutureSeries,
    },
    {
      name: acceptedTxt,
      data: acceptedWorksSeries,
    },
  ];

  const chartOptions: ApexOptions = {
    chart: {
      type: 'line',

      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      strokeDashArray: 3,
      row: {
        colors: [theme.color.graphiteB96A, 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },
    stroke: {
      width: [2, 2, 2, 2],
      curve: 'straight',
      dashArray: [4, 0, 4, 0],
    },

    colors: [
      theme.color.pitch,
      theme.color.chartPlanned,
      theme.color.chartPlanned,
      theme.color.chartAccepted,
    ],

    xaxis: {
      min: minXPreviousDate,
      type: 'datetime',
      tooltip: {
        enabled: false,
      },
      labels: {
        rotateAlways: xAxis.length > 12, // more than 12 items in x axis will rotate the labels
        showDuplicates: false,
        minHeight: 70,
        style: {
          colors: theme.color.pitch,
          fontSize: '12px',
          cssClass: 'apexcharts-xaxis-title',
        },
        format: 'M/yyyy',
        datetimeUTC: false,
        offsetX: 38,
      },
    },
    yaxis: [
      {
        min: minY > 0 ? 0 : minY,
        forceNiceScale: true,
        labels: {
          formatter: (value: number) => {
            if (value) {
              return `${big.priceFormat(new Big(value), 0)}`;
            }

            return '';
          },
        },
      },
    ],
    tooltip: {
      shared: true,
      custom: (tooltipProps) => {
        const { seriesIndex, dataPointIndex, w } = tooltipProps;
        let tooltipHtml = '';

        const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];

        const timeStampAsDate = new Date(timestamp ?? 0);

        const tooltipTitle = dateFormat.format(timeStampAsDate);

        const series = w.config.series as SeriesData[];

        series.forEach((s, i) => {
          if (s.data && s.data.length > 0) {
            const value = s.data.find((datapoint) => {
              const xEoMonth = getEOMonth(new Date(datapoint.x));

              if (
                getEOMonth(timeStampAsDate).getTime() === xEoMonth.getTime()
              ) {
                return true;
              }

              return false;
            });

            if (value !== null && value !== undefined) {
              tooltipHtml += `
              <div class='tooltip-item'>
                <span style="color: ${w.globals.colors[i]}">
                  ${w.config.series[i].name}:
                </span>
                ${big.priceFormat(new Big(value.y), 0)}
              </div>
                `;
            }
          }
        });

        return `<div class="apexcharts-tooltip-title">${tooltipTitle}</div>${tooltipHtml}`;
      },
    },
    legend: {
      horizontalAlign: 'left',
      fontWeight: 600,
      fontSize: '12.5',
      offsetX: 10,
      itemMargin: {
        horizontal: 12,
        vertical: 10,
      },
      markers: {
        radius: 4,
        offsetX: -5,
        offsetY: -3,
        width: 12,
        height: 2,
      },
    },
    annotations: {
      xaxis: [
        {
          x: firstDayOfCurrentMonth.getTime(),
          x2: lastDayOfCurrentMonth.getTime(),
          fillColor: theme.color.lightBlue,
          opacity: 0.3,
        },
      ],
      points: [
        {
          x: scheduleInUse
            ? new Date().getTime()
            : getEOMonth(new Date()).getTime(),
          y: pocAsEuros,
          marker: {
            size: 5,
            strokeColor: theme.color.chartPlanned,
            radius: 2,
          },
          label: { text: currentAcceptedTxt },
        },
      ],
    },
  };

  if (!workPackage) {
    return null;
  }

  return (
    <StyledContainer>
      <WorkPackageSummary
        workPackage={workPackage}
        poc={percentageOfCompletion}
        isScheduleInUse={scheduleInUse}
      />
      <ChartContainer todayPosition={todayPosition}>
        <Chart
          ref={setRef}
          options={chartOptions}
          series={chartSeries}
          width="100%"
          height="100%"
        />
      </ChartContainer>
    </StyledContainer>
  );
};

export default WorkSectionGraph;

const StyledContainer = styled.section`
  padding-right: 1rem;

  height: 25rem;

  display: grid;

  background: linear-gradient(${theme.color.white}, ${theme.color.dropdownBg});

  grid-template-columns: 1fr 2.8fr;
`;

const ChartContainer = styled.section<{ todayPosition: number | null }>`
  padding-left: 2rem;
  min-width: 50rem;

  ${(props) =>
    props.todayPosition
      ? css`
          text.apexcharts-xaxis-title:nth-of-type(${props.todayPosition}) {
            fill: ${props.theme.color.blue};
          }
        `
      : null}
  /* stylelint-disable selector-max-class -- The classname is styling chart's tooltip */
  .tooltip-item {
    margin: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.margin[4]};
  }
`;
