import { APIActualCostAttachmentFile } from '../../types/api';
import { ID } from '../../types/general';

import { ExtractActionTypes, makeAction } from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  downloadContentForIframe,
  BackendError,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import {
  getActualCostAttachmentFilesRequest,
  getActualCostImageRequest,
} from '../reducers/actualCostAttachmentFiles';

export type ActualCostAttachmentFilesAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeAction('getActualCostAttachmentFilesStarted')<{ orderId: string }>(),
  ...makeAction('getActualCostAttachmentFilesFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostAttachmentFilesSuccess')<{
    orderId: string;
    actualCostAttachmentFiles: APIActualCostAttachmentFile[];
  }>(),
  ...makeAction('getActualCostImageFileStarted')<{ actualCostId: string }>(),
  ...makeAction('getActualCostImageFileFailure')<{
    actualCostId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostImageFileSuccess')<{
    actualCostId: string;
    imageUrl: string;
  }>(),
};

export const {
  getActualCostAttachmentFilesStarted,
  getActualCostAttachmentFilesSuccess,
  getActualCostAttachmentFilesFailure,
  getActualCostImageFileStarted,
  getActualCostImageFileSuccess,
  getActualCostImageFileFailure,
} = actionCreators;

const fetchActualCostAttachmentFilesForOrder = (orderId: ID) => {
  return GET<APIActualCostAttachmentFile[]>(
    `v1/orders/${orderId}/actual-costs/attachment-files`
  );
};

export const requestActualCostAttachmentFilesForOrder = (
  orderId: ID
): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(fetchActualCostAttachmentFilesForOrder, {
      args: [orderId],
      isPending: flow(
        getActualCostAttachmentFilesRequest(orderId),
        remoteData.isLoading
      ),
      initialAction: getActualCostAttachmentFilesStarted({ orderId }),
      successActionCreator: (actualCostAttachmentFiles) =>
        getActualCostAttachmentFilesSuccess({
          orderId,
          actualCostAttachmentFiles,
        }),
      failureActionCreator: (error) =>
        getActualCostAttachmentFilesFailure({
          orderId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

const getActualCostImageFile = async (actualCostId: string) => {
  return downloadContentForIframe(
    `v1/attachments/actual-costs/${actualCostId}`
  );
};

export const fetchActualCostImageFile = (actualCostId: string) =>
  createAsyncThunk(getActualCostImageFile, {
    args: [actualCostId],
    isPending: flow(
      getActualCostImageRequest(actualCostId),
      remoteData.isLoading
    ),
    initialAction: getActualCostImageFileStarted({ actualCostId }),
    successActionCreator: (imageUrl) =>
      getActualCostImageFileSuccess({
        actualCostId,
        imageUrl,
      }),
    failureActionCreator: (error) =>
      getActualCostImageFileFailure({
        actualCostId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
