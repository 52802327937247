import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* stylelint-disable selector-max-type -- Resetting global styles with type selectors in
one place is okay, no need to refctor away */
  html {
    height: 100%;
    font-size: 1em; /* 16px by default */
  }

  body {
    height: 100%;

    background: ${(props) => props.theme.color.white};

    color: ${(props) => props.theme.color.pitch};
    font-size: ${(props) => props.theme.fontSize.base};
    font-family: 'Graphik', Arial, Helvetica, sans-serif;

  }

  #root {
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3 {
    margin: 0;
  }

  h1 {
    font-family: 'BuildingSans', sans-serif;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize.h1};
    line-height: ${(props) => props.theme.fontSize.h1};
  }

  h2 {
    font-family: 'BuildingSans', sans-serif;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize.h2};
    line-height: ${(props) => props.theme.fontSize.h2};
  }

  h3 {
    font-family: 'Graphik', sans-serif;
    font-size: ${(props) => props.theme.fontSize.h3};
    line-height: ${(props) => props.theme.fontSize.h3};
  }

  /* Reset annoying table styles */
  table,
  thead,
  tbody,
  tfoot,
  tr,
  th,
  td {
    margin: 0;

    border: none;
    border-color: inherit;

    padding: 0;

    text-align: left;
    font-weight: inherit;

    border-spacing: 0;
    border-collapse: inherit;
  }

  /* Stylings for components that cannot be styled elsewhere: */

  /* React-select list styles: */
  /* stylelint-disable selector-max-class -- Third party libraries need to be styled by classnames */
  .rc-virtual-list,
  .rc-select-item-empty {
    position: relative;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

    padding: 0.5rem;

    min-width: 100%;

    background: white;

    z-index: 60;
  }
  .rc-select-item {
    padding: 0 0.5rem;
    display: flex;
    line-height: 1.5rem;
    cursor: pointer;
  }
  .rc-select-item-group {
    font-weight: bold;
  }
  .rc-select-item-option-active {
    background-color: ${({ theme }) => theme.color.graphiteB76};
  }
  .rc-select-dropdown-hidden {
    display: none;
  }

`;

export default GlobalStyle;
