import { Dispatch } from 'react';

import {
  APIPurchaseInvoiceAttachmentFile,
  APIUpdatedEntities,
  RawAPIUpdatedEntities,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities } from '../../types/mappers';

import {
  ExtractActionTypes,
  makeApiActions,
  makeAction,
} from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  POST,
  downloadContentForIframe,
  BackendError,
} from '../../utils/api';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { Thunk, createAsyncThunk } from '../../utils/thunk';

import { getInvoiceHeaderImageRequest } from '../reducers/invoiceAttachmentFiles';

export type InvoiceAttachmentFilesAction = ExtractActionTypes<
  typeof actionCreators
>;

const actionCreators = {
  ...makeApiActions('get', 'invoiceAttachmentFiles')<
    APIPurchaseInvoiceAttachmentFile[]
  >(),
  ...makeApiActions('post', 'invoiceAttachmentFiles')<APIUpdatedEntities>(),
  ...makeAction('getInvoiceImageFileStarted')<{ invoiceHeaderId: string }>(),
  ...makeAction('getInvoiceImageFileFailure')<{
    invoiceHeaderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getInvoiceImageFileSuccess')<{
    invoiceHeaderId: string;
    imageUrl: string;
  }>(),
};
export const {
  getInvoiceAttachmentFilesStarted,
  getInvoiceAttachmentFilesSuccess,
  getInvoiceAttachmentFilesFailure,
  postInvoiceAttachmentFilesSuccess,
  postInvoiceAttachmentFilesFailure,
  getInvoiceImageFileStarted,
  getInvoiceImageFileSuccess,
  getInvoiceImageFileFailure,
} = actionCreators;

const fetchInvoiceAttachmentFilesForOrder = (orderId: ID) => {
  return GET<APIPurchaseInvoiceAttachmentFile[]>(
    `v1/orders/${orderId}/purchase-invoice/attachment-files`
  );
};

export const requestInvoiceAttachmentFilesForOrder = (orderId: ID): Thunk => (
  dispatch
) => {
  dispatch(getInvoiceAttachmentFilesStarted());
  fetchInvoiceAttachmentFilesForOrder(orderId).then(
    (response) => {
      dispatch(getInvoiceAttachmentFilesSuccess(response));
    },
    (error) => {
      dispatch(
        getInvoiceAttachmentFilesFailure(apiErrorHandlingWithDecode(error))
      );
    }
  );
};

export const postInvoiceAttachmentsUpload = async (
  invoiceHeaderId: string,
  files: File[],
  dispatch: Dispatch<any>
): Promise<void> => {
  const data = new FormData();
  files.forEach((file) => data.append('file', file));

  return POST<RawAPIUpdatedEntities>(
    `v1/attachments/purchase-invoice-headers/${invoiceHeaderId}/files`,
    data
  ).then(
    async (response) =>
      dispatch(
        postInvoiceAttachmentFilesSuccess(await mapRawUpdatedEntities(response))
      ),
    async (error) => {
      dispatch(
        postInvoiceAttachmentFilesFailure(apiErrorHandlingWithDecode(error))
      );

      throw error;
    }
  );
};

const getInvoiceImageFile = async (invoiceHeaderId: string) => {
  return downloadContentForIframe(
    `v1/attachments/purchase-invoice-headers/${invoiceHeaderId}`
  );
};

export const fetchInvoiceImageFile = (invoiceHeaderId: string) =>
  createAsyncThunk(getInvoiceImageFile, {
    args: [invoiceHeaderId],
    isPending: flow(
      getInvoiceHeaderImageRequest(invoiceHeaderId),
      remoteData.isLoading
    ),
    initialAction: getInvoiceImageFileStarted({ invoiceHeaderId }),
    successActionCreator: (imageUrl) =>
      getInvoiceImageFileSuccess({
        invoiceHeaderId,
        imageUrl,
      }),
    failureActionCreator: (error) =>
      getInvoiceImageFileFailure({
        invoiceHeaderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });
