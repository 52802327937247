import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Big from 'big.js';
import styled from 'styled-components';

import { getHierarchyEntryById } from '../../../../store/reducers/target/hierarchy';
import {
  getTargetViewFilterSearchWord,
  getTargetViewHierarchyEntryFilterState,
  getTargetViewHierarchyEntryOpenState,
} from '../../../../store/reducers/ui';

import { uiStateTargetRowHierarchyEntryToggled } from '../../../../store/actions/ui';

import ArrowIcon from '../../../../components/ArrowIcon';
import Cell, { LeftPaddedCell } from '../../../../components/Cell';
import { PrimaryRow } from '../../../../components/Table';

import * as big from '../../../../utils/big';
import { isClickOrKeyboardSelection } from '../../../../utils/mouseOrKeyInteraction';

import { fontSizeCss, paddingLeftCss } from '../../../../styles/additionals';
import TargetRow from './TargetRow';

type TargetRowHierarchyEntryProps = {
  entryId: string;
};

const TargetRowHierarchyEntryRow = ({
  entryId,
}: TargetRowHierarchyEntryProps) => {
  const dispatch = useDispatch();

  const onClickOrKeyboardSelection = (
    e: React.MouseEvent | React.KeyboardEvent
  ) => {
    e.stopPropagation();

    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      dispatch(uiStateTargetRowHierarchyEntryToggled(entryId));
    }
  };

  const targetRowHierarchyEntry = useSelector(getHierarchyEntryById(entryId));
  const filterSearchWord = useSelector(getTargetViewFilterSearchWord());

  const isOpen = useSelector(getTargetViewHierarchyEntryOpenState(entryId));

  const isFiltered = useSelector(
    getTargetViewHierarchyEntryFilterState(entryId)
  );

  const isAnyFilter = filterSearchWord.length > 0;

  if (!targetRowHierarchyEntry) {
    return null;
  }

  if (isAnyFilter && !isFiltered) {
    return null;
  }

  return (
    <>
      <StyledPrimaryRow
        key={entryId}
        onClick={onClickOrKeyboardSelection}
        onKeyPress={onClickOrKeyboardSelection}
        tabIndex={0}
        depth={targetRowHierarchyEntry.depth}
      >
        <StyledLeftPaddedCell depth={targetRowHierarchyEntry.depth}>
          <StyledIconDiv depth={targetRowHierarchyEntry.depth}>
            <ArrowIcon
              isOpen={isOpen}
              openAltTextKey="revenue.table.mainRow.open"
              closedAltTextKey="revenue.table.mainRow.closed"
            />
          </StyledIconDiv>
          {targetRowHierarchyEntry.referenceNumber}
        </StyledLeftPaddedCell>
        <StyledCell colSpan={2} depth={targetRowHierarchyEntry.depth}>
          {targetRowHierarchyEntry.description}
        </StyledCell>
        <StyledCell align="right" depth={targetRowHierarchyEntry.depth}>
          {`${big.amountFormat(
            targetRowHierarchyEntry.quantity ?? new Big(0)
          )}${
            targetRowHierarchyEntry.unit
              ? ` ${targetRowHierarchyEntry.unit}`
              : ''
          }`}
        </StyledCell>
        <StyledCell align="right" depth={targetRowHierarchyEntry.depth}>
          {`${big.priceFormat(
            targetRowHierarchyEntry.unitPrice ?? new Big(0)
          )}${
            targetRowHierarchyEntry.unit
              ? `/${targetRowHierarchyEntry.unit}`
              : ''
          }`}
        </StyledCell>
        <StyledCell align="right" depth={targetRowHierarchyEntry.depth}>
          {big.priceFormat(targetRowHierarchyEntry.totalAmount ?? new Big(0))}
        </StyledCell>
        <StyledCell depth={targetRowHierarchyEntry.depth} />
        <StyledCell depth={targetRowHierarchyEntry.depth} />
      </StyledPrimaryRow>
      {isOpen ? (
        <>
          {targetRowHierarchyEntry.childIds.map((id) => (
            <TargetRowHierarchyEntryRow
              key={`hierarchyEntry-${id}`}
              entryId={id}
            />
          ))}
          {targetRowHierarchyEntry.targetRowIds.map((id) => (
            <TargetRow key={`targetRow-${id}`} rowId={id} />
          ))}
        </>
      ) : null}
    </>
  );
};

type PrimaryRowAndCellProps = {
  depth?: number;
};

const StyledCell = styled(Cell)<PrimaryRowAndCellProps>`
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledLeftPaddedCell = styled(LeftPaddedCell)<PrimaryRowAndCellProps>`
  ${(props) => fontSizeCss(props.depth)}
`;

const StyledIconDiv = styled.div<PrimaryRowAndCellProps>`
  ${(props) => paddingLeftCss(props.depth)}
`;

const StyledPrimaryRow = styled(PrimaryRow)<PrimaryRowAndCellProps>`
  border: 2px solid ${(props) => props.theme.color.white};
  ${(props) => fontSizeCss(props.depth)}
`;

export default TargetRowHierarchyEntryRow;
