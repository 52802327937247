import React from 'react';

import styled from 'styled-components';

import { IconLogoWithText } from '../../assets/svg';

const Container = styled('div')`
  padding: 8rem;

  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${({ theme }) => theme.color['M3/ref/primary/primary99']};

  z-index: 1;

  gap: 1rem;
`;

// mimic Paper component from Material UI
const Paper = styled.div`
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

  background-color: ${({ theme }) => theme.color.white};

  color: rgba(0, 0, 0, 0.87);

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Content = styled(Paper)`
  position: relative;

  padding: 4rem;

  min-width: min(572px, 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  z-index: 1;

  gap: 1.5rem;
`;

const BackgroundTop = styled('div')`
  position: absolute;
  top: 0;

  width: 100%;
  height: 18.75rem;

  background-color: ${({ theme }) => theme.color.backgroundTop};

  z-index: 0;
`;

const Logo = styled(IconLogoWithText)<{ color?: string }>`
  fill: ${(props) => props.color ?? 'black'};
  /* stylelint-disable selector-max-type -- svgs inside the button are most easily
  styled with type selectors */
  & path {
    fill: ${(props) => props.color ?? 'black'};
  }
`;

export default function AuthContainer({
  children,
}: React.PropsWithChildren<{}>) {
  return (
    <>
      <BackgroundTop />
      <Container>
        <Content>
          <Logo fill="black" width={250} />
          {children}
        </Content>
      </Container>
    </>
  );
}
