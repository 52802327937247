import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { setLastActiveProcurementArea } from '../../../store/actions/ui';

import { APIOrder } from '../../../types/api';
import { SelectOption as Column } from '../../../types/ui';

import {
  DeltaBigCostValue,
  BigPriceValue,
  DeltaBigProfitValue,
} from '../../../components/BigValue';
import { IconTextButton } from '../../../components/Buttons';
import Cell from '../../../components/Cell';
import { SecondaryRow } from '../../../components/Table';
import UnsettledCostsButton from '../../../components/UnsettledCostsButton';

import { isClickOrKeyboardSelection } from '../../../utils/mouseOrKeyInteraction';

import { IconDownload } from '../../../assets/svg';

import { generateUrl, routes } from '../../../routes';
import { ContractorText, StyledDescription } from './General';
import StatusPill from './OrderStatusPill';
import { PREDICTION_CHANGE_DISPLAY_TRESHOLD } from './ProcurementArea';

type RouteParams = {
  projectId: string;
};

type Props = {
  order: APIOrder;
  columns: Column[];
};

const ProjectViewOrderRow = ({ order, columns }: Props) => {
  const { projectId } = useParams<RouteParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickOrder = () => {
    dispatch(setLastActiveProcurementArea(order.procurementAreaId));
    history.push(
      generateUrl({ route: routes.ORDER, projectId, orderId: order.id })
    );
  };

  const onKeyPressOrder = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (isClickOrKeyboardSelection(e)) {
      e.preventDefault();
      onClickOrder();
    }
  };

  const onClickReceive = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    dispatch(setLastActiveProcurementArea(order.procurementAreaId));
    history.push(
      generateUrl({
        route: routes.ORDER,
        projectId,
        orderId: order.id,
        viewMode: 'receive',
      })
    );
  };

  // We need to stop propagation on receive keypress too so that the keypress
  // event for whole row does not get called. onClick will be called also on a
  // keypress as it is a button, so we don't need to handle navigation here.
  const onKeyPressReceive = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (isClickOrKeyboardSelection(e)) {
      e.stopPropagation();
      e.preventDefault();
      onClickReceive(e);
    }
  };

  const renderRowValue = (column: Column) => {
    if (!order) {
      return;
    }

    const targetDiff = order.targetTotal.minus(order.predictionTotal);
    switch (column.id) {
      case '1':
        return order.visibleCode;
      case '2':
        return (
          <>
            <StyledDescription>{order.name}</StyledDescription>
            {order.contractor ? (
              <ContractorText>| {order.contractor}</ContractorText>
            ) : null}
          </>
        );
      case '3':
        return <BigPriceValue value={order.targetTotal} decimals={0} />;
      case '4':
        return targetDiff.abs().gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
          <DeltaBigProfitValue value={targetDiff} decimals={0} />
        ) : null;
      case '5':
        return <BigPriceValue value={order.predictionTotal} decimals={0} />;
      case '6':
        return order.predictionChangeFromLatest
          .abs()
          .gte(PREDICTION_CHANGE_DISPLAY_TRESHOLD) ? (
          <DeltaBigCostValue
            value={order.predictionChangeFromLatest}
            decimals={0}
          />
        ) : null;
      case '7':
        return <BigPriceValue value={order.contractTotal} decimals={0} />;
      case '8':
        return <BigPriceValue value={order.changeOrdersTotal} decimals={0} />;
      case '9':
        return <BigPriceValue value={order.reservesTotal} decimals={0} />;
      case '11':
        return <BigPriceValue value={order.receivedTotal} decimals={0} />;
      case '12':
        return (
          <BigPriceValue
            value={order.predictionTotal.minus(order.receivedTotal)}
            decimals={0}
          />
        );
      case '13':
        return (
          <>
            <UnsettledCostsButton orderId={order.id} documentType="invoice" />
            <UnsettledCostsButton
              orderId={order.id}
              documentType="actualCost"
            />
          </>
        );
      case '14':
        return <StatusPill statusId={order.statusId} />;
      case '15':
        return (
          <BigPriceValue value={order.additionalTargetTotal} decimals={0} />
        );

      default:
        return null;
    }
  };

  const setCellAlignment = (
    column: Column
  ): 'right' | 'left' | 'center' | undefined => {
    if (column.id === '1' || column.id === '2') {
      return undefined;
    }

    return 'right';
  };

  return (
    <SecondaryRow
      clickable
      data-testid={`project-view-order-${order.id}`}
      onClick={onClickOrder}
      onKeyPress={onKeyPressOrder}
      tabIndex={0}
    >
      <Cell />
      {columns
        .filter((c) => c.selected)
        .map((column) => (
          <Cell
            key={column.id}
            colSpan={column.id === '2' ? 2 : 1}
            align={setCellAlignment(column)}
            data-testid={`order-prediction-change-${order.id}-${column.id}`}
          >
            {renderRowValue(column)}
          </Cell>
        ))}

      <Cell>
        <IconTextButton
          icon={IconDownload}
          onClick={onClickReceive}
          onKeyPress={onKeyPressReceive}
          visibleOnlyOnHover
        >
          Vastaanota
        </IconTextButton>
      </Cell>
    </SecondaryRow>
  );
};

export default ProjectViewOrderRow;
