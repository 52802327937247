import React from 'react';
import { useSelector } from 'react-redux';

import { InvoiceHeader } from '../../../../../../store/reducers/invoiceHeader';
import { selectProjectUsers } from '../../../../../../store/reducers/projectUsers';
import { getTasks } from '../../../../../../store/reducers/tasks';

import { fetchProjectUsers } from '../../../../../../store/actions';

import useRemoteData from '../../../../../../hooks/useRemoteData';

import { FileUploadModal } from '../../../../FileUploadModal';
import DelegateInvoiceModal from './DelegateInvoiceModal';

interface InvoiceModalProps {
  modalType: string | undefined;
  handleClose: () => void;
  invoiceHeader?: InvoiceHeader;
  projectId: string;
}

const InvoiceModal = (props: InvoiceModalProps) => {
  const { modalType, handleClose, invoiceHeader, projectId } = props;

  const tasks = useSelector(getTasks);

  const projectUsers =
    useRemoteData(
      selectProjectUsers(projectId ?? ''),
      fetchProjectUsers(projectId ?? '')
    ) ?? [];

  switch (modalType) {
    case 'DELEGATE_INVOICE_MODAL':
      return (
        <DelegateInvoiceModal
          projectUsers={projectUsers}
          onClose={handleClose}
          invoiceHeader={invoiceHeader!}
          tasks={tasks}
        />
      );

    case 'UPLOAD_INVOICE_ATTACHMENT_MODAL':
      return (
        <FileUploadModal
          titleTextId="order.receiveMode.invoice.attachment.upload"
          parentId={invoiceHeader!.id}
          onClose={handleClose}
        />
      );

    default:
      return null;
  }
};

export default InvoiceModal;
