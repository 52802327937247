import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { getActualCostImageRequest } from '../../store/reducers/actualCostAttachmentFiles';
import { getInvoiceHeaderImageRequest } from '../../store/reducers/invoiceAttachmentFiles';
import { getOuterBarState } from '../../store/reducers/ui';

import {
  fetchActualCostImageFile,
  fetchInvoiceImageFile,
} from '../../store/actions';

import useRemoteData from '../../hooks/useRemoteData';

import { isLoading } from '../../utils/remoteData';

import { IconCloseWhite } from '../../assets/svg';

import { AppContext } from '../../context';
import { OrderParams, routes } from '../../routes';
import ActualCostLinesTable from '../../views/OrderView/components/ActualCostLines';
import InvoiceLinesTable from '../../views/OrderView/components/PurchaseInvoiceLines';
import { IconButton } from '../Buttons';
import { Spinner } from '../Loading';
import Txt from '../Txt';

const Content = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 60%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  background: ${(props) => props.theme.color.embedBackground};
`;

const TableContainer = styled.div`
  margin-left: ${(props) => props.theme.margin[4]};

  border-top: 3px solid ${({ theme }) => theme.color.rowBorder};
  border-left: ${(props) => props.theme.color.black} 3px solid;

  padding-left: ${(props) => props.theme.margin[4]};

  width: 40vw;
  height: 40%;

  display: flex;
  flex-direction: column;

  flex-grow: 1;

  overflow-y: scroll;
`;

const PaddedIconButton = styled(IconButton)`
  margin: ${(props) => props.theme.margin[8]};

  border-radius: ${(props) => props.theme.margin[4]};

  width: ${(props) => props.theme.margin[40]};
  height: ${(props) => props.theme.margin[40]};

  display: flex;
  justify-content: center;

  background-color: ${(props) => props.theme.color.black};
`;

type OuterBarProps = PropsWithChildren<{
  onClose: () => void;
}>;

const OuterBar = ({ onClose }: OuterBarProps) => {
  const outerBarState = useSelector(getOuterBarState());

  const location = useLocation();

  const pathMatchesOrderPaths = matchPath<OrderParams>(location.pathname, {
    path: [routes.ORDER],
    exact: true,
  });

  const projectId = pathMatchesOrderPaths?.params.projectId ?? null;
  const orderId = pathMatchesOrderPaths?.params.orderId ?? null;

  const selector =
    outerBarState?.type === 'invoice_header'
      ? getInvoiceHeaderImageRequest(outerBarState.contentId)
      : getActualCostImageRequest(outerBarState?.contentId ?? '');

  const fetchData =
    outerBarState?.type === 'invoice_header'
      ? fetchInvoiceImageFile(outerBarState?.contentId)
      : fetchActualCostImageFile(outerBarState?.contentId ?? '');

  const iframeContent = useRemoteData(selector, fetchData, !!outerBarState);

  const showSpinner = isLoading(useSelector(selector));

  if (!outerBarState) {
    return null;
  }

  const linesTable = () => {
    if (outerBarState?.type === 'invoice_header') {
      return (
        <InvoiceLinesTable
          invoiceHeaderId={outerBarState?.contentId}
          projectId={projectId}
          orderId={orderId}
        />
      );
    }

    return (
      <ActualCostLinesTable
        actualCostId={outerBarState?.contentId}
        projectId={projectId}
        orderId={orderId}
      />
    );
  };

  return (
    <AppContext.Consumer>
      {(context) => {
        return (
          context.outerBarContainer &&
          ReactDOM.createPortal(
            <>
              <Content>
                <PaddedIconButton onClick={onClose} icon={IconCloseWhite} />
                {showSpinner ? (
                  <StyledSpinner size="4rem" light />
                ) : (
                  <>
                    <Embed
                      key={iframeContent}
                      src={iframeContent}
                      type="application/pdf"
                    />
                    <StyledH2>
                      <Txt id="order.invoice.no.image" />
                    </StyledH2>
                  </>
                )}
              </Content>
              <TableContainer>{linesTable()}</TableContainer>
            </>,
            context.outerBarContainer
          )
        );
      }}
    </AppContext.Consumer>
  );
};

const Embed = styled.embed`
  height: 30vw;
  z-index: 20;
`;

const StyledH2 = styled.h2`
  position: absolute;
  top: 100px;

  width: 300px;

  font-weight: bold;
  color: white;
  text-align: center;

  z-index: 10;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: ${(props) => props.theme.margin[128]};

  display: flex;
  align-items: center;
  justify-content: center;

  align-self: center;
`;

export default OuterBar;
