import React from 'react';
import { useHistory } from 'react-router-dom';

import { Big } from 'big.js';
import styled from 'styled-components';

import { getCurrentCompanyReportingPeriod } from '../../../../store/reducers/companyReportingPeriod';
import { APIProject } from '../../../../store/reducers/project';
import {
  getProjectCurrentPeriodActualPoC,
  getProjectCurrentPeriodBasedOnPreviouslyPlannedPoC,
  getProjectCurrentPeriodLatestSnapshotPlannedPoC,
  getProjectCurrentPeriodPlannedPoC,
} from '../../../../store/reducers/schedule/projectTimeline';
import { getProjectWorkPackages } from '../../../../store/reducers/workPackage';

import { fetchWorkPackagesForProject } from '../../../../store/actions';
import { requestCompanyReportingPeriods } from '../../../../store/actions/companyReportingPeriod';
import { getProjectTimelineForProject } from '../../../../store/actions/schedule/projectTimeline';

import useRemoteData from '../../../../hooks/useRemoteData';
import useTxt from '../../../../hooks/useTxt';

import { IconButton } from '../../../../components/Buttons';
import TextInput from '../../../../components/Input/TextInput';
import { Table } from '../../../../components/Table';
import Tooltip from '../../../../components/Tooltip';
import Txt from '../../../../components/Txt';

import { isPresent } from '../../../../utils/general';
import getWidths from '../../../../utils/headerWidth';

import {
  IconTriangleUp,
  IconTriangleDown,
  IconInvalid,
} from '../../../../assets/svg';

import * as localization from '../../../../localization';
import { generateUrl, routes } from '../../../../routes';
import globalTheme from '../../../../styles/theme';
import TableRow from './TableRow';

type RevenueTableProps = {
  project: APIProject | undefined;
  selectedSnapshotTypeId: '1' | '2' | '3';
  descriptionValue: string;
  disabled: boolean;
  onChange: (e: React.ChangeEvent) => void;
  errorMessage: string | undefined;
};

const NewSnapshotTable = ({
  project,
  selectedSnapshotTypeId,
  descriptionValue,
  disabled,
  onChange,
  errorMessage,
}: RevenueTableProps) => {
  const profit = (project?.revenueTotal ?? new Big(0)).sub(
    project?.costPredictionTotal ?? new Big(0)
  );

  const projectId = project ? project.id : '';

  const profitChange = (
    project?.revenuePredictionChangeFromLatest ?? new Big(0)
  ).sub(project?.costPredictionChangeFromLatest ?? new Big(0));

  const previousProfit = (
    project?.latestSnapshotRevenueTotal ?? new Big(0)
  ).sub(project?.latestSnapshotCostsTotal ?? new Big(0));

  const projectWorkPackages =
    useRemoteData(
      getProjectWorkPackages(projectId),
      fetchWorkPackagesForProject(projectId)
    ) ?? [];

  const isPoCCalculatedForAll = projectWorkPackages.every(
    (wp) =>
      wp.scheduleTaskIds.length > 0 ||
      (isPresent(wp.startDate) && isPresent(wp.endDate))
  );

  const tasksOrDatesNotInUse =
    isPresent(project?.nextgenDefaultScheduleViewId) && !isPoCCalculatedForAll;

  const actualPoC = useRemoteData(
    getProjectCurrentPeriodActualPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const previouslyPlannedPoC = useRemoteData(
    getProjectCurrentPeriodBasedOnPreviouslyPlannedPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const plannedPoC = useRemoteData(
    getProjectCurrentPeriodPlannedPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const latestSnapshotPoC = useRemoteData(
    getProjectCurrentPeriodLatestSnapshotPlannedPoC({
      projectId,
    }),
    getProjectTimelineForProject({ projectId })
  );

  const currentReportingPeriod =
    useRemoteData(
      getCurrentCompanyReportingPeriod(),
      requestCompanyReportingPeriods
    ) ?? undefined;

  const currentReportingPeriodDescription = currentReportingPeriod
    ? currentReportingPeriod.description
    : '-';

  const actualPercentageOfCompletion = actualPoC
    ? actualPoC.percentageOfCompletion
    : new Big(0);

  const previouslyPlannedPercentageOfCompletion = previouslyPlannedPoC
    ? previouslyPlannedPoC.percentageOfCompletion
    : new Big(0);

  const plannedPercentageOfCompletion = plannedPoC
    ? plannedPoC?.percentageOfCompletion
    : new Big(0);

  const latestSnapshotPercentageOfCompletion = latestSnapshotPoC
    ? latestSnapshotPoC.percentageOfCompletion
    : new Big(0);

  const calculatePoCDifference = (): Big => {
    switch (selectedSnapshotTypeId) {
      case '1':
        return actualPercentageOfCompletion.sub(
          latestSnapshotPercentageOfCompletion
        );
      case '2':
        return plannedPercentageOfCompletion.sub(
          latestSnapshotPercentageOfCompletion
        );
      default:
        return previouslyPlannedPercentageOfCompletion.sub(
          latestSnapshotPercentageOfCompletion
        );
    }
  };

  const selectedPoC = (): Big => {
    switch (selectedSnapshotTypeId) {
      case '1':
        return actualPercentageOfCompletion;
      case '2':
        return plannedPercentageOfCompletion;
      default:
        return previouslyPlannedPercentageOfCompletion;
    }
  };

  const descriptionText = useTxt('reporting.addNewSnapshot.modal.description');

  const revenueSectionName = useTxt('reporting.addNewSnapshot.modal.revenue');
  const profitSectionName = useTxt('reporting.addNewSnapshot.modal.profit');
  const costSectionName = useTxt('reporting.addNewSnapshot.modal.costs');

  const costPlanSectionName = useTxt(
    'reporting.addNewSnapshot.modal.costPrediction'
  );
  const reservesSectionName = useTxt('reporting.addNewSnapshot.modal.reserves');
  const contractSectionName = useTxt('reporting.addNewSnapshot.modal.contract');

  const changeOrdersSectionName = useTxt(
    'reporting.addNewSnapshot.modal.changeOrders'
  );
  const targetSectionName = useTxt('reporting.addNewSnapshot.modal.target');

  const pocSectionName = useTxt(
    'reporting.addNewSnapshot.modal.percentageOfCompletion'
  );

  const pocRowTooltip = useTxt('reporting.addNewSnapshot.modal.pocRowTooltip');

  if (!project) {
    return null;
  }

  return (
    <>
      <TextInput
        label={descriptionText}
        name="description"
        flexContainer
        value={descriptionValue}
        onChange={onChange}
        disabled={disabled}
        errorMessage={errorMessage}
      />
      <ValueSection
        txtId="reporting.addNewSnapshot.modal.reportingPeriod"
        value={currentReportingPeriodDescription}
      />
      <Table>
        <TableHeader />
        <tbody>
          <TableRow
            rowType="PriceNoIndent"
            sectionName={revenueSectionName}
            currentValue={project.revenueTotal}
            previousValue={project.latestSnapshotRevenueTotal}
            changeValue={project.revenuePredictionChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={
                  project.revenuePredictionChangeFromLatest ?? new Big(0)
                }
                differenceType="profit"
              />
            }
          />
          <TableRow
            rowType="PriceNoIndent"
            sectionName={profitSectionName}
            currentValue={profit}
            previousValue={previousProfit}
            changeValue={profitChange}
            changeIcon={
              <ChangeIcon difference={profitChange} differenceType="profit" />
            }
          />
          <TableRow rowType="PriceNoIndent" sectionName={costSectionName} />
          <TableRow
            rowType="PriceSmallIndent"
            sectionName={costPlanSectionName}
            currentValue={project.costPredictionTotal}
            previousValue={project.latestSnapshotCostsTotal}
            changeValue={project.costPredictionChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={
                  project.costPredictionChangeFromLatest ?? new Big(0)
                }
                differenceType="costs"
              />
            }
          />
          <TableRow
            rowType="PriceLargeIndent"
            sectionName={contractSectionName}
            currentValue={project.contractTotal}
            previousValue={project.latestSnapshotContractTotal}
            changeValue={project.contractChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={project.contractChangeFromLatest ?? new Big(0)}
                differenceType="costs"
              />
            }
          />
          <TableRow
            rowType="PriceLargeIndent"
            sectionName={reservesSectionName}
            currentValue={project.reservesTotal}
            previousValue={project.latestSnapshotReservesTotal}
            changeValue={project.reservesChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={project.reservesChangeFromLatest ?? new Big(0)}
                differenceType="costs"
              />
            }
          />
          <TableRow
            rowType="PriceLargeIndent"
            sectionName={changeOrdersSectionName}
            currentValue={project.changeOrdersTotal}
            previousValue={project.latestSnapshotChangeOrdersTotal}
            changeValue={project.changeOrdersChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={project.changeOrdersChangeFromLatest ?? new Big(0)}
                differenceType="costs"
              />
            }
          />
          <TableRow
            rowType="PriceSmallIndent"
            sectionName={targetSectionName}
            currentValue={project.targetTotal}
            previousValue={project.latestSnapshotTargetTotal}
            changeValue={project.targetChangeFromLatest}
            changeIcon={
              <ChangeIcon
                difference={project.targetChangeFromLatest ?? new Big(0)}
                differenceType="costs"
              />
            }
          />
          <TableRow
            rowType="PercentageNoIndent"
            sectionName={pocSectionName}
            currentValue={selectedPoC()}
            previousValue={latestSnapshotPercentageOfCompletion}
            changeValue={calculatePoCDifference()}
            rowTooltip={pocRowTooltip}
            invalidIcon={
              tasksOrDatesNotInUse ? (
                <InvalidIconWithTooltip projectId={projectId} />
              ) : undefined
            }
            changeIcon={
              <ChangeIcon difference={new Big(0)} differenceType="profit" />
            }
          />
        </tbody>
      </Table>
    </>
  );
};

export const tableColumns = {
  sectionName: { align: 'left', relativeWidth: 4 },
  current: { align: 'right', relativeWidth: 3 },
  previous: { align: 'right', relativeWidth: 3 },
  difference: { align: 'right', relativeWidth: 3 },
  icon: { align: 'left', relativeWidth: 1 },
} as const;

const getColumnPercent = getWidths(tableColumns);

type ThProps = {
  name: keyof typeof tableColumns;
  paddingDisabled?: boolean;
};

const Th = styled.th<ThProps>`
  padding: ${({ paddingDisabled, theme: { margin } }) =>
    paddingDisabled ? '0rem' : `${margin[8]} ${margin[8]}`};
  width: ${({ name }) => `${getColumnPercent(name)}%`};
  align-items: center;
  text-align: ${({ name }) => tableColumns[name].align};
`;

const textIdHead = 'reporting.addNewSnapshot.modal.table.header.';

const TxtTh = ({ name, paddingDisabled }: ThProps) => {
  const tipDifference = useTxt(
    'reporting.addNewSnapshot.modal.differenceTooltip'
  );

  if (name !== 'difference') {
    return (
      <Th name={name} paddingDisabled={paddingDisabled}>
        <Txt id={`${textIdHead}${name}` as const} component="b" />
      </Th>
    );
  }

  return (
    <Th name={name} paddingDisabled={paddingDisabled}>
      <Tooltip tip={tipDifference} className="compressed-tooltip" place="right">
        <Txt id={`${textIdHead}${name}` as const} component="b" />
      </Tooltip>
    </Th>
  );
};

const TableHeader = () => (
  <thead>
    <HeaderTr>
      <TxtTh name="sectionName" />
      <TxtTh name="current" />
      <TxtTh name="previous" />

      <TxtTh name="difference" />

      <TxtTh name="icon" />
    </HeaderTr>
  </thead>
);

const HeaderTr = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.color.graphiteB91};
`;

const IncreaseIconGreen = () => (
  <IconTriangleUp
    width={globalTheme.margin[10]}
    height={globalTheme.margin[10]}
    color={globalTheme.color.positiveGreen}
    data-testid="IncreaseIconGreen"
  />
);

const DecreaseIconRed = () => (
  <IconTriangleDown
    width={globalTheme.margin[10]}
    height={globalTheme.margin[10]}
    color={globalTheme.color.negativeRed}
    data-testid="DecreaseIconRed"
  />
);

const IncreaseIconRed = () => (
  <IconTriangleUp
    width={globalTheme.margin[10]}
    height={globalTheme.margin[10]}
    color={globalTheme.color.negativeRed}
    data-testid="IncreaseIconRed"
  />
);

const DecreaseIconGreen = () => (
  <IconTriangleDown
    width={globalTheme.margin[10]}
    height={globalTheme.margin[10]}
    color={globalTheme.color.positiveGreen}
    data-testid="DecreaseIconGreen"
  />
);

type ChangeIconProps = {
  differenceType: 'costs' | 'profit';
  difference: Big;
};

const ChangeIcon = ({ difference, differenceType }: ChangeIconProps) => {
  if (difference.gt(1) && differenceType === 'profit') {
    return <IncreaseIconGreen />;
  }

  if (difference.lt(-1) && differenceType === 'profit') {
    return <DecreaseIconRed />;
  }

  if (difference.gt(1) && differenceType === 'costs') {
    return <IncreaseIconRed />;
  }

  if (difference.lt(-1) && differenceType === 'costs') {
    return <DecreaseIconGreen />;
  }

  return null;
};

const InvalidIconButton = styled(IconButton)`
  margin: ${(props) => `0 ${props.theme.margin[8]}`};
  vertical-align: middle;
`;

const InvalidIconWithTooltip = ({ projectId }: { projectId: string }) => {
  const tooltip = useTxt(
    'reporting.addNewSnapshot.modal.pocRowTooltip.invalid'
  );

  const history = useHistory();

  const toWorkSectionView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const url = generateUrl({
      route: routes.WORKSECTIONS,
      projectId,
    });

    history.push(url);
  };

  return (
    <Tooltip tip={tooltip} className="compressed-tooltip" place="right">
      <InvalidIconButton
        onClick={toWorkSectionView}
        icon={IconInvalid}
        data-testid="poc-data-missing-for-some-worksection-button"
        type="button"
      />
    </Tooltip>
  );
};

type ValueSectionProps = {
  value: string;
  txtId: localization.TextId;
};

const ValueSection = ({ value, txtId }: ValueSectionProps) => {
  return (
    <Section>
      <Label>
        <Txt id={txtId} />
      </Label>
      <ValueDiv>{value}</ValueDiv>
    </Section>
  );
};

const ValueDiv = styled.div`
  padding: ${(props) => `0 ${props.theme.margin[16]}`};

  height: ${(props) => props.theme.margin[40]};
  width: 100%;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.color.pitch};
`;

const Label = styled.label`
  height: ${(props) => props.theme.margin[36]};
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const Section = styled.div`
  padding-bottom: 20px;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default NewSnapshotTable;
