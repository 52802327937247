import Big from 'big.js';
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';

import {
  APIUpdatedEntities,
  APIActualCostPutBody,
  RawAPIUpdatedEntities,
  APIActualCostPutRehandleBody,
} from '../../types/api';
import { ID } from '../../types/general';
import { mapRawUpdatedEntities, solveHandlingState } from '../../types/mappers';

import {
  makeApiActions,
  ExtractActionTypes,
  makeAction,
} from '../../utils/actionCreators';
import {
  GET,
  apiErrorHandlingWithDecode,
  BackendError,
  PUT,
} from '../../utils/api';
import { dateString, bigString } from '../../utils/decoders';
import { flow } from '../../utils/function';
import * as remoteData from '../../utils/remoteData';
import { createAsyncThunk, Thunk } from '../../utils/thunk';

import { ActionTypes } from '../actionTypes';
import {
  ActualCost,
  selectOrderActualCostsRequests,
  selectAnalysisRowActualCostsRequests,
  getActualCostMoveRequest,
  getActualCostUpdateRequest,
  getActualCostRehandleRequest,
} from '../reducers/actualCost';
import { SortableKey } from '../reducers/actualCosts/sortActualCosts';

export type ActualCostAction = ExtractActionTypes<typeof actionCreators>;

const actionCreators = {
  ...makeAction('getActualCostsStarted')<{ orderId: string }>(),
  ...makeAction('getActualCostsFailure')<{
    orderId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostsSuccess')<{
    orderId: string;
    actualCosts: ActualCost[];
  }>(),
  ...makeAction('toggleActualcostSort')<{
    sortableKey: SortableKey;
  }>(),
  ...makeAction('putActualCostStarted')<{ actualCostId: string }>(),
  ...makeAction('putActualCostFailure')<{
    actualCostId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putActualCostSuccess')<
    APIUpdatedEntities & { actualCostId: string }
  >(),
  ...makeApiActions('put', 'actualCostConvert')<APIUpdatedEntities>(),

  ...makeAction('getActualCostsForAnalysisRowStarted')<{
    analysisRowId: string;
  }>(),
  ...makeAction('getActualCostsForAnalysisRowFailure')<{
    analysisRowId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('getActualCostsForAnalysisRowSuccess')<{
    analysisRowId: string;
    actualCosts: ActualCost[];
  }>(),
  ...makeAction('putActualCostMoveStarted')<{
    actualCostId: string;
  }>(),
  ...makeAction('putActualCostMoveFailure')<{
    actualCostId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putActualCostMoveSuccess')<
    APIUpdatedEntities & {
      actualCostId: string;
      orderId: string;
    }
  >(),
  ...makeAction('putActualCostRehandleStarted')<{ actualCostId: string }>(),
  ...makeAction('putActualCostRehandleFailure')<{
    actualCostId: string;
    error: BackendError | undefined;
  }>(),
  ...makeAction('putActualCostRehandleSuccess')<
    APIUpdatedEntities & { actualCostId: string }
  >(),
};
export const {
  getActualCostsStarted,
  getActualCostsFailure,
  getActualCostsSuccess,
  putActualCostConvertStarted,
  putActualCostConvertFailure,
  putActualCostConvertSuccess,
  putActualCostStarted,
  putActualCostFailure,
  putActualCostSuccess,
  toggleActualcostSort,
  getActualCostsForAnalysisRowSuccess,
  getActualCostsForAnalysisRowFailure,
  getActualCostsForAnalysisRowStarted,
  putActualCostMoveFailure,
  putActualCostMoveStarted,
  putActualCostMoveSuccess,
  putActualCostRehandleStarted,
  putActualCostRehandleFailure,
  putActualCostRehandleSuccess,
} = actionCreators;

const apiActualCostType = t.exact(
  t.type({
    id: t.string,
    entryNo: t.string,
    orderId: t.string,
    projectId: t.union([t.string, t.null]),
    date: dateString,
    documentNumber: t.string,
    description: t.string,
    amount: bigString,
    supplierId: t.union([t.string, t.null]),
    supplierName: t.union([t.string, t.null]),
    invoiceLink: t.union([t.string, t.null]),
    actualCostsAttachmentIds: t.array(t.string),
    createdAt: dateString,
    updatedAt: dateString,
    statusId: t.string,
    handleAmountPending: bigString,
    isHandleable: t.boolean,
    isMovable: t.boolean,
    actualCostsDetailLineIds: t.array(t.string),
    latestStatusChangeAt: t.union([dateString, t.null]),
    latestUpdater: t.union([t.string, t.null]),
    comment: t.union([t.string, t.null]),
  })
);

export async function toActualCosts(u: unknown): Promise<ActualCost[]> {
  const apiActualCosts = await tPromise.decode(t.array(apiActualCostType), u);

  return apiActualCosts.map(
    ({ amount, handleAmountPending, isHandleable, ...rest }) => {
      return {
        ...rest,
        amount: new Big(amount || 0),
        handleAmountPending,
        handlingState: solveHandlingState(
          isHandleable,
          handleAmountPending,
          new Big(0)
        ),
      };
    }
  );
}

// TODO FIXME: validate response
async function getActualCostsForOrder(orderId: ID): Promise<ActualCost[]> {
  const response = await GET(`v1/orders/${orderId}/actual-costs`);

  return toActualCosts(response);
}

async function getActualCostsForAnalysisRow(
  analysisRowId: ID
): Promise<ActualCost[]> {
  const response = await GET(
    `v1/custom-fields/list-items/${analysisRowId}/actual-costs`
  );

  return toActualCosts(response);
}

export const fetchActualCostsForOrder = (orderId: ID) =>
  createAsyncThunk(getActualCostsForOrder, {
    args: [orderId],
    isPending: flow(
      selectOrderActualCostsRequests(orderId),
      remoteData.isLoading
    ),
    initialAction: getActualCostsStarted({ orderId }),
    successActionCreator: (actualCosts) =>
      getActualCostsSuccess({ orderId, actualCosts }),
    failureActionCreator: (error) =>
      getActualCostsFailure({
        orderId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

export const fetchActualCostsForAnalysisRow = (analysisRowId: ID) =>
  createAsyncThunk(getActualCostsForAnalysisRow, {
    args: [analysisRowId],
    isPending: flow(
      selectAnalysisRowActualCostsRequests(analysisRowId),
      remoteData.isLoading
    ),
    initialAction: getActualCostsForAnalysisRowStarted({ analysisRowId }),
    successActionCreator: (actualCosts) =>
      getActualCostsForAnalysisRowSuccess({ analysisRowId, actualCosts }),
    failureActionCreator: (error) =>
      getActualCostsForAnalysisRowFailure({
        analysisRowId,
        error: apiErrorHandlingWithDecode(error),
      }),
  });

// TODO FIXME: validate response
const putActualCost = async ({
  actualCostId,
  body,
}: {
  actualCostId: ID;
  body: APIActualCostPutBody;
}): Promise<APIUpdatedEntities> =>
  PUT<RawAPIUpdatedEntities>(`v1/actual-costs/${actualCostId}`, body).then(
    mapRawUpdatedEntities
  );

export const updateActualCost = (
  actualCostId: ID,
  body: APIActualCostPutBody
): Thunk =>
  createAsyncThunk(putActualCost, {
    args: [{ actualCostId, body }],
    initialAction: putActualCostStarted({ actualCostId }),
    isPending: flow(
      getActualCostUpdateRequest(actualCostId),
      remoteData.isLoading
    ),
    failureActionCreator: (error) =>
      putActualCostFailure({
        actualCostId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      putActualCostSuccess({ ...updatedEntities, actualCostId }),
  });

export const convertActualCost = (actualCostId: ID): Thunk => (dispatch, _) => {
  dispatch(putActualCostConvertStarted());

  PUT<RawAPIUpdatedEntities>(`v1/actual-costs/${actualCostId}/convert`, {})
    .then(mapRawUpdatedEntities)
    .then(
      (updatedEntities) => {
        dispatch(putActualCostConvertSuccess(updatedEntities));
      },
      (error) => {
        dispatch(
          putActualCostConvertFailure(apiErrorHandlingWithDecode(error))
        );
      }
    );
};

const moveActualCost = async (
  actualCostId: string,
  orderId: string
): Promise<APIUpdatedEntities> => {
  const response = await PUT<RawAPIUpdatedEntities>(
    `v1/actual-costs/${actualCostId}/move`,
    { orderId }
  );

  return mapRawUpdatedEntities(response);
};

type MoveActualCostRequest = {
  actualCostId: string;
  orderId: string;
};

export const requestMoveActualCost = ({
  actualCostId,
  orderId,
}: MoveActualCostRequest): Thunk => (dispatch) => {
  dispatch(
    createAsyncThunk(moveActualCost, {
      args: [actualCostId, orderId],
      isPending: flow(
        getActualCostMoveRequest(actualCostId),
        remoteData.isLoading
      ),
      initialAction: putActualCostMoveStarted({ actualCostId }),
      successActionCreator: (updatedEntities): ActionTypes => {
        return putActualCostMoveSuccess({
          ...updatedEntities,
          actualCostId,
          orderId,
        });
      },
      failureActionCreator: (error) =>
        putActualCostMoveFailure({
          actualCostId,
          error: apiErrorHandlingWithDecode(error),
        }),
    })
  );
};

// TODO FIXME: validate response
const putActualCostRehandle = async ({
  actualCostId,
  body,
}: {
  actualCostId: ID;
  body: APIActualCostPutRehandleBody;
}): Promise<APIUpdatedEntities> =>
  PUT<RawAPIUpdatedEntities>(
    `v1/actual-costs/${actualCostId}/rehandle`,
    body
  ).then(mapRawUpdatedEntities);

export const rehandleActualCostRequest = (
  actualCostId: ID,
  body: APIActualCostPutRehandleBody
): Thunk =>
  createAsyncThunk(putActualCostRehandle, {
    args: [{ actualCostId, body }],
    initialAction: putActualCostRehandleStarted({ actualCostId }),
    isPending: flow(
      getActualCostRehandleRequest(actualCostId),
      remoteData.isLoading
    ),
    failureActionCreator: (error) =>
      putActualCostRehandleFailure({
        actualCostId,
        error: apiErrorHandlingWithDecode(error),
      }),
    successActionCreator: (updatedEntities) =>
      putActualCostRehandleSuccess({ ...updatedEntities, actualCostId }),
  });
